<template>
  <div class="row">
    <template v-if="isShowHomeTemplate">
      <div class="col-12 col-lg-6">
        <div :class="$style.root">
          <StatusBox />
          <DocumentRadio />
          <div :class="$style.line" />
          <ConfirmBox />
        </div>
      </div>

      <div :class="[
          'col-12 col-lg-5 col-xl-4 offset-lg-1',
          $style.sidebar,
      ]">
        <SidebarBox />
        <IconsList :list="iconList" />
      </div>
    </template>

    <template v-else>
      <div class="col-12 col-lg-6">
        <div :class="$style.root">
          <StatusBox />
          <DocumentRadio />
          <div :class="$style.line" />
          <ConfirmBox />
          <div :class="$style.line" />
          <div :class="$style.slotcontent">
            <slot name="content" />
          </div>
        </div>
      </div>

      <div :class="[
          'col-12 col-lg-5 col-xl-4 offset-lg-1',
          $style.sidebar,
      ]">
        <slot name="sidebar" />
        <ConfirmButton />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isNil } from 'lodash';

import { AVAILABLE_STATUSES } from './constants';

/* eslint-disable import/extensions */
import StatusBox from './components/StatusBox';
import DocumentRadio from './components/DocumentRadio';
import ConfirmBox from './components/ConfirmBox';
import SidebarBox from './components/SidebarBox';
import IconsList from './components/IconsList';
import ConfirmButton from './components/ConfirmButton';

export default {
  name: 'CheckoutStatus',

  components: {
    SidebarBox,
    StatusBox,
    DocumentRadio,
    ConfirmBox,
    ConfirmButton,
    IconsList,
  },

  props: {
    status: {
      type: String,
      default: AVAILABLE_STATUSES.paid,
      validator: (value) => Object.values(AVAILABLE_STATUSES).includes(value),
    },

    button: {
      title: String,
      target: String,
      url: String,
    },

    zizid: String,

    isHome: Boolean,
  },

  data: () => ({
    documentCurrent: null,
    documentNumber: '',
    isReadyToSend: false,
    iconList: [{
      icon: 'history',
      title: 'Voor 16:30 besteld, volgende werkdag in huis',
    }, {
      icon: 'shield',
      title: 'Veilig en discreet',
    }],
  }),

  provide() {
    return {
      getIsReadyToSend: () => this.isReadyToSend,
      setIsReadyToSend: this.handleSetIsReadyToSend,

      getDocumentCurrent: () => this.documentCurrent,
      setDocumentCurrent: this.handleDocumentChoose,

      getDocumentNumber: () => this.documentNumber,
      setDocumentNumber: this.handleDocumentInput,

      getButton: () => this.$props.button,
      getZIZId: () => this.$props.zizid,
    };
  },

  computed: {
    ...mapGetters(['IS_HOME_VISIT']),

    isShowHomeTemplate() {
      return isNil(this.isHome) ? this.IS_HOME_VISIT : this.isHome;
    },
  },

  methods: {
    handleSetIsReadyToSend(value) {
      this.isReadyToSend = value;
    },

    handleDocumentInput(value) {
      this.documentNumber = value;
    },

    handleDocumentChoose(documentTypeId) {
      this.documentCurrent = documentTypeId;
      this.documentNumber = '';
    },
  },
};
</script>

<style lang="scss" module scoped>
  .root {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .line {
    height: 2px;
    background-color: $grey-500;
    width: 100%;
    pointer-events: none;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 64px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .slotcontent {
    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
</style>
