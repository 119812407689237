<template>
  <div>
     <span v-if="!this.zizId" class="field-group__message field-group__message-error">
      De url is niet correct, gebruik aub de link uit de uitnodiging.
    </span>
    <div ref="tripetto"></div>
  </div>
</template>

<script>
import { run } from '@tripetto/runner-autoscroll';
import { Export } from '@tripetto/runner';

import attachments from '../tripetto/filestore';

import definition from '../tripetto/follow-up/definition.json';
import license from '../tripetto/license.json';
import styles from '../tripetto/styles.json';
import l10n from '../tripetto/intake/l10n.json';

import tripettoTracking from '../helpers/tripettoTracking';

import ziz from '../helpers/ziz';

export default {
  name: 'FollowUpTripettoFormComponent',
  data() {
    return {
      zizId: null,
      isLoaded: false,
      fields: [],
    };
  },
  props: {
    postTo: {
      type: String,
      default: '/receive/follow-up',
    },
  },
  mounted() {
    this.zizId = ziz.getId();
    if (this.zizId) {
      run({
        definition,
        removeBranding: true,
        license: license.key,
        element: this.$refs.tripetto,
        styles,
        l10n,
        attachments,
        onSubmit: this.onSubmit,
        onReady: this.onReady,
        onAction: (type, tripettoDefinition, tripettoBlock) => {
          tripettoTracking(type, tripettoDefinition, tripettoBlock, 'Follow up form');
        },
      });
    }
  },
  methods: {
    onReady() {
      this.isLoaded = true;
      this.zizId = ziz.getId();
    },
    onSubmit(instance) {
      const self = this;
      const fields = Export.fields(instance); // TODO .fields = deprecated method

      setTimeout(() => {
        this.$http.post(self.postTo, { zizId: this.zizId, followUp: fields.fields })
          .then((response) => console.log(response))
          .catch((error) => console.log(error));
        this.$store.state.loading = true;
      }, 1000);
    },
  },
};

</script>

<style scoped>

</style>
