<template>
    <ZizAccordion inline-template :items="items">
        <ul class="accordion">
            <li class="accordion__row" v-for="(item, index) in this.$attrs.items" :key="index">
                <ListItem :label="item.blockLabel">
                    <div v-html="item.blockContent"></div>
                </ListItem>
            </li>
        </ul>
    </ZizAccordion>
</template>

<script>
import ZizAccordion from './widgets/Accordion/index';
import ListItem from './widgets/Accordion/item';

export default {
  name: 'DefaultZizAccordion',
  components: {
    ZizAccordion,
    ListItem,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="scss">

</style>
