<template>
  <div v-if="current" :class="$style.root">
    <label for="document-input-field">{{ current.label }}</label>
    <div :class="$style.inputWrap">
      <input
          autofocus
          id="document-input-field"
          type="text"
          :placeholder="current.placeholder"
          :class="$style.input"
          :disabled="isSaved"
          v-model="currentNumber"
          v-mask="current.mask"
      />
      <Link
        v-if="isSaved"
        tag="button"
        type="button"
        :class="$style.clearButton"
        @click.native="handleClearClick"
      >Wijzigen</Link>
    </div>
    <Link
        v-if="!isSaved"
        tag="button"
        type="button"
        @click.native="handleShowPopup"
    >{{ current.modalLinkText }}</Link>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import Link from '@/components/Link';

export default {
  name: 'DocumentInput',

  components: {
    Link,
  },

  inject: [
    'getIsSaved',
    'getDocumentNumber',
    'getCurrentInput',
    'toggleSaveDocument',
    'setDocumentNumber',
    'getIsButtonDisabled',
    'toggleShowPopup',
  ],

  computed: {
    currentNumber: {
      get() {
        return this.documentNumber.toUpperCase();
      },
      set(value) {
        this.handleChange(value.toUpperCase());
      },
    },

    isSaved() {
      return this.getIsSaved();
    },

    documentNumber() {
      return this.getDocumentNumber();
    },

    current() {
      return this.getCurrentInput();
    },
  },

  methods: {
    handleClearClick() {
      this.toggleSaveDocument();
    },

    handleChange(value) {
      this.setDocumentNumber(value);
    },

    handleShowPopup() {
      this.toggleShowPopup(true);
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
