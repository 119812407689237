<template>
  <div
    v-if="label"
    :class="[
        'faq-accordion',
        { 'faq-accordion_active': isShowContent },
    ]"
  >
    <button
        type="button"
        class="faq-accordion__button"
        @click="handleShow"
    >
      <span class="faq-accordion__button-label">{{ label }}</span>
      <Icons
          name="chevron-down"
          class="faq-accordion__button-icon"
      />
    </button>

    <transition
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div
        v-show="isShowContent"
        class="faq-accordion__content"
      >
        <div class="faq-accordion__content-inner">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Icons from '@/components/Icons';

export default {
  name: 'FaqAccordion',

  components: {
    Icons,
  },

  props: {
    label: String,
  },

  data: () => ({
    isShowContent: false,
  }),

  methods: {
    handleShow() {
      this.isShowContent = !this.isShowContent;
    },
    beforeEnter: (el) => el.style.setProperty('height', 0),
    enter: (el) => el.style.setProperty('height', `${el.scrollHeight}px`),
    afterEnter: (el) => el.style.setProperty('height', null),
    beforeLeave: (el) => el.style.setProperty('height', `${el.scrollHeight}px`),
    leave: (el) => setTimeout(() => el.style.setProperty('height', 0), 0),
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
