<template>
  <LoadingComponent v-if="$store.state.loading"/>
</template>

<script>
import LoadingComponent from './LoadingComponent.vue';

export default {
  name: 'LoadingWrapperComponent',
  components: {
    LoadingComponent,
  },
};
</script>

<style scoped>

</style>
