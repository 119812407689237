<template>
  <div :class="$style.root">
    <div :class="$style.iconWrap">
      <Icons
        name="package"
        :class="$style.icon"
      />
    </div>

    <div>
      <Heading type="h4" :class="$style.title">Je hebt gekozen voor thuistesten.</Heading>
      <p :class="$style.text">
        Je ontvangt binnen 1 — 2 werkdagen de thuisafname met de post.
        Je hoeft hiervoor niet thuis te blijven.
        <br /><br/>
        Je ontvangt een email ter bevestiging met verdere instructies.
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import Heading from '@/components/ui/Heading';
import Icons from '@/components/Icons';

export default {
  name: 'SidebarBox',

  components: {
    Heading,
    Icons,
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
