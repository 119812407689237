<template>
    <ButtonModal :title="'Meer informatie'">
        <template v-slot:trigger>
            <span
               class="disease-row__more-info-link">
                <span class="disease-row__more-info-text">Meer info</span>
                <span class="disease-row__more-info-icon"></span>
            </span>
        </template>
        <MoreInfoComponent :product-info="productInfo" />
    </ButtonModal>
</template>
<script>
import ButtonModal from './common/ButtonModal';
import MoreInfoComponent from './MoreInfoComponent';

export default {
  name: 'ButtonModalPredictor',
  components: {
    ButtonModal,
    MoreInfoComponent,
  },
  props: {
    productInfo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.disease-container {
    .disease-row__inner-wrapper {
        .circles-box {
            flex: 1;
            justify-content: flex-end;
        }
    }
}

.disease-row {
    &__title-and-info {
        display: flex;
        flex: 1;
        justify-content: space-between;

        @media (max-width: 1199px) {
            flex: 1.5;
        }

        @media (max-width: 991px) {
            flex: initial;
            gap: .75rem;
        }
    }

    &__more-info-link {
        font-size: 1rem;
        font-weight: 500;
        color: var(--Colors-Green-green-500, #02BD72);
        cursor: pointer;
        text-decoration: underline;

        @media (max-width: 991px) {
            display: inline-block;
            text-align: center;
            min-width: 1rem;
            width: 100%;
            min-height: 1rem;
            height: 100%;
        }
    }

    &__more-info-text {
        color: var(--Colors-Green-green-500, #02BD72);

        @media (max-width: 991px) {
            display: none;
        }
    }

    &__more-info-icon {
        @media (max-width: 991px) {
            display: inline-block;
            background: no-repeat center url('../assets/images/i-icon.svg');
            background-size: contain;
            height: 1rem;
            width: .25rem;
        }
    }
}
</style>
