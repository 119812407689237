<template>
  <div :class="$style.root">
    <DescriptionSection title="2. Korte intake">{{ headText }}</DescriptionSection>

    <div v-if="!isHideButton">
      <ConfirmButton />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import DescriptionSection from '../DescriptionSection';
import ConfirmButton from '../ConfirmButton';

export default {
  name: 'ConfirmBox',

  components: {
    DescriptionSection,
    ConfirmButton,
  },

  props: {
    isHideButton: Boolean,
  },

  inject: ['getIsReadyToSend', 'getButton'],

  data: () => ({
    headText: 'Een ingevulde intake is belangrijk, o.a. voor gratis behandeling indien nodig. De invultijd is minder dan 1 minuut.',
  }),
};
</script>

<style lang="scss" module scoped>
  .root {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-up(lg) {
      gap: 24px;
    }
  }
</style>
