<script>
let lastTime = 0;

export default {
  name: 'ProductsBanner',

  props: {
    number: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    countedNumber: 1,
    isAnimationEnd: false,
    request: null,
  }),

  methods: {
    animateNumber(timestamp) {
      if (this.number > this.countedNumber) {
        const timeDifference = (timestamp ?? 0) - lastTime;

        if (timeDifference >= 16) {
          this.countedNumber += 1;
          lastTime = timestamp;
        }

        this.request = requestAnimationFrame(this.animateNumber);
      } else {
        cancelAnimationFrame(this.request);
        this.isAnimationEnd = true;
      }
    },
  },

  mounted() {
    setTimeout(this.animateNumber, 200);
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
