<template>
  <ContentSwitcherComponent :breakpoint-switch="breakpointSwitch">
    <template #switch-from>
      <RiskIndicatorV2 :risk="risk"/>
    </template>
    <template #switch-to>
        <RiskIndicatorV2
            :risk="(risk > riskLimit ? riskLimit : risk)"
            :riskLimit="riskLimit"
            :indicatorType="indicatorType"
        >
          <div v-if="risk > riskLimit"
               class="results-products__item-risk-number">+{{ risk - riskLimit }}</div>
        </RiskIndicatorV2>
    </template>
  </ContentSwitcherComponent>
</template>
<script>
import ContentSwitcherComponent from './ContentSwitcherComponent';
import RiskIndicatorV2 from './RiskIndicatorV2';

export default {
  name: 'RiskIndicatorV2Switcher',
  components: {
    ContentSwitcherComponent,
    RiskIndicatorV2,
  },
  props: {
    risk: {
      type: Number,
      default: 0,
    },
    riskLimit: {
      type: Number,
      default: 5,
    },
    breakpointSwitch: {
      type: Number,
      default: 515,
    },
    indicatorType: {
      type: String,
      default: 'circle',
    },
  },
};
</script>

<style scoped lang="scss">
</style>
