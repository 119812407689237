<template>
  <div
    :class="[
        'accordion__item',
        extraClasses,
        { 'accordion__item_active': isShowContent },
    ]"
  >
    <button
      type="button"
      class="accordion__item-button"
      @click="handleShow"
    >
      <span class="accordion__item-button-label">{{ label }}</span>
      <Icons
        name="chevron-down"
        class="accordion__item-button-icon"
      />
    </button>

    <transition
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:after-enter="afterEnter"
        v-on:before-leave="beforeLeave"
        v-on:leave="leave"
    >
      <div
        v-show="isShowContent"
        class="accordion__item-content"
      >
        <div class="accordion__item-content-inner">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Icons from '@/components/Icons';

export default {
  name: 'ListItem',

  components: {
    Icons,
  },

  props: {
    label: String,
    extraClasses: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isShowContent: false,
  }),

  methods: {
    handleShow() {
      this.isShowContent = !this.isShowContent;
    },
    beforeEnter: (el) => el.style.setProperty('height', 0),
    enter: (el) => el.style.setProperty('height', `${el.scrollHeight}px`),
    afterEnter: (el) => el.style.setProperty('height', null),
    beforeLeave: (el) => el.style.setProperty('height', `${el.scrollHeight}px`),
    leave: (el) => setTimeout(() => el.style.setProperty('height', 0), 0),
  },
};
</script>
