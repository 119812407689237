<template>
  <ul
    v-if="list"
    class="icons-list"
  >
    <li
      v-for="({icon, title}, index) in list"
      :key="index"
      class="icons-list__item"
    >
      <Icons
        v-if="icon"
        :name="icon"
        class="icons-list__item-icon"
      />
      <div class="icons-list__item-text">{{ title }}</div>
    </li>
  </ul>
</template>

<script>
/* eslint-disable import/extensions */
import Icons from '@/components/Icons';

export default {
  name: 'IconsList',

  components: {
    Icons,
  },

  props: {
    list: Array,
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
