<template>
  <div :class="$style.root">
    <Icons
      name="google"
      :class="$style.logo"
    />

    <div :class="$style.rating">
      <div
        :class="$style.filled"
        :style="filledStyles"
      >
        <Icons
          v-for="n in maxRating"
          :key="`star-${n}`"
          name="star"
          :class="$style.star"
        />
      </div>
    </div>

    <div
      v-if="isShowDigits"
      :class="$style.digits"
    >{{ slashRating }}</div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Icons from '@/components/Icons';

export default {
  name: 'GoogleRating',

  components: {
    Icons,
  },

  props: {
    rating: {
      type: [Number, String],
      default: 3,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    isShowDigits: Boolean,
  },

  computed: {
    slashRating() {
      return `${parseFloat(this.rating).toFixed(1)}/${parseFloat(this.maxRating).toFixed(1)}`;
    },
    filledStyles() {
      return {
        width: `${parseFloat(this.rating).toFixed(1) * (100 / this.maxRating)}%`,
      };
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
