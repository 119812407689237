<template>
  <span :class="[
      'ziz-loader',
      `ziz-loader--${$props.size}`,
  ]" />
</template>

<script>
const AVAILABLE_SIZES = ['sm', 'md'];

export default {
  name: 'ZIZLoader',
  props: {
    size: {
      size: String,
      default: AVAILABLE_SIZES[0],
      validator: (value) => AVAILABLE_SIZES.includes(value),
    },
  },
};
</script>

<style lang="scss">
@import './index';
</style>
