<template>
  <div class="popup-video__wrapper d-flex"
       @click="$emit('closePopup')">
    <div class="container m-auto">
      <div class="row position-relative">
        <div class="col-10 offset-1">
          <div class="popup-video__close" @click="$emit('closePopup')"></div>
          <div class="popup-video__video embed-responsive embed-responsive-16by9">
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupVideoComponent',
  props: {
  },
  methods: {
    escapeHandler(e) {
      if (e.key === 'Escape') {
        this.$emit('closePopup');
      }
    },
  },
  mounted() {
    document.body.classList.add('modal-open');
    document.addEventListener('keydown', this.escapeHandler);
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open');
    document.removeEventListener('keydown', this.escapeHandler);
  },
};
</script>

<style scoped>

</style>
