const ziz = {
  // if customer came from email
  // e.g. 2d8fa6a9-4752-4899-b59e-bf54bc1a59b2
  ZIZ_ID_PATTERN: /[a-z\d]{8}-[a-z\d]{4}-[a-z\d]{4}-[a-z\d]{4}-[a-z\d]{12}/,
  // (inner uid from Dashboard, if customer came from invoke SMS
  // e.g. 62dfb6762d5cc
  ZIZ_SHORT_ID: /[a-z\d]{13}/,
  getId() {
    const param = 'zizid';
    const queryStr = window.location.search;
    const urlParams = new URLSearchParams(queryStr);

    if (urlParams.has(param)) {
      const value = urlParams.get(param);
      if (this.ZIZ_ID_PATTERN.test(value) || this.ZIZ_SHORT_ID.test(value)) {
        return value;
      }
    }

    return null;
  },
};

export default ziz;
