<template>
  <div>
    <div
        class="achievements__item mx-0 mx-md-2 mb-0 mb-md-0 play popup-video__button"
        @click="toggleShow">
          {{ title }}
    </div>
    <PopupVideoComponent v-if="showPopup"
                         @closePopup="showPopup = false">
      <slot/>
    </PopupVideoComponent>
  </div>
</template>

<script>
import PopupVideoComponent from './PopupVideoComponent.vue';

export default {
  name: 'ShowVideoButtonComponent',
  components: {
    PopupVideoComponent,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Meer info',
    },
  },
  methods: {
    toggleShow() {
      this.showPopup = !this.showPopup;
    },
  },
};
</script>

<style scoped>

</style>
