<template>
  <div :class="$style.charts">
    <div :class="$style['charts__side-text']">
      <span>Kans op een soa</span>
    </div>
    <div :class="$style['charts__arrow-container']">
      <div></div>
      <div :class="$style['charts__arrow-wrapper']">
        <div :class="[$style['charts__arrow'], isExpanded ? $style['animate'] : '']"></div>
      </div>
      <div></div>
    </div>
    <div id="charts" :class="$style['charts__results']">
      <div :class="$style['charts__result']">
        <div :class="$style['charts__result-number']">3%</div>
        <div
            :class="[$style['charts__result-rectangle'], isExpanded ? $style['expanded'] : '']"
        ></div>
      </div>
      <div :class="$style['charts__result']">
        <div :class="$style['charts__result-number']">10%</div>
        <div
            :class="[$style['charts__result-rectangle'], isExpanded ? $style['expanded'] : '']"
        ></div>
      </div>
      <div :class="$style['charts__result']">
        <div :class="$style['charts__result-number']">38%</div>
        <div
            :class="[$style['charts__result-rectangle'], isExpanded ? $style['expanded'] : '']
        "></div>
      </div>
    </div>
    <div :class="$style['charts__results-texts']">
      <div :class="$style['charts__result-description']">Basis</div>
      <div :class="$style['charts__result-description']">Verhoogd</div>
      <div :class="$style['charts__result-description']">Sterk verhoogd</div>
    </div>
  </div>
</template>

<script>
import expandMixin from '@/mixins/expandMixin';

export default {
  name: 'ChartsHighRisk',
  mixins: [expandMixin],
  data() {
    return {
      observableSelector: '#charts',
    };
  },
};
</script>

<style module lang="scss">
  @import './index';

  .charts__result {
    min-height: 13rem;
  }

  .charts__result:nth-child(1) .charts__result-rectangle {
    background: var(--Colors-Green-green-500, #02BD72);
  }

  .charts__result:nth-child(2) .charts__result-rectangle {
    background: var(--Foundation-Red-red-500, #F85131);
  }

  .charts__result:nth-child(3) .charts__result-rectangle {
    background: var(--Foundation-Red-red-700, #B03A23);
  }

  .charts__result:nth-child(1) .expanded {
    height: 1rem;
  }

  .charts__result:nth-child(2) .expanded {
    height: 3.125rem;
  }

  .charts__result:nth-child(3) .expanded {
    height: 11.875rem;
  }
</style>
