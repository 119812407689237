<template>
    <div
        class="quote-container contact__location"
        :class="{'quote-container_with-photo': isPhotoSet}"
    >
      <div v-if="addressHeading || contentDescription"
           class="contact__location-addr"
           :class="{'contact__location-addr_with-photo': isPhotoSet}"
      >
        <div class="contact__location-addr-text-wrapper">
          <div v-if="addressHeading" class="contact__location-addr-title">{{ addressHeading }}</div>
          <div v-if="contentDescription"
               class="contact__location-addr-text" v-html="contentDescription"></div>
        </div>
        <div v-if="branchPhoto.url" class="contact__location-addr-photo-wrapper">
          <img class="contact__location-addr-photo" :src="branchPhoto.url" :alt="branchPhoto.alt" />
        </div>
      </div>

      <div v-if="phone" class="contact__location-phone-box">
        <a class="contact__location-phone" :href="phoneLink">{{phoneTitle}}</a>
      </div>

      <a v-if="contactMap"
          :href="contactMap"
          class="contact__location_location-link"
          target="_blank"
          rel="noopener noreferrer"
      >Start route beschrijving</a>

      <AccordionComponent
          :title="'Openingstijden'"
          :border="'none'"
          class="contact__location_white-box">
        <div class="contact__location-time">
          <ul class="contact__location-time-list">
            <li v-for="(day, index) in openHours"
                :key="index"
                class="contact__location-time-item"
            >
              <span>{{ day[0] }}</span><span>{{ day[1] }}</span>
            </li>
          </ul>
        </div>
      </AccordionComponent>
    </div>
</template>
<script>
import AccordionComponent from './common/Accordion';

export default {
  name: 'ContactLocationComponent',
  components: {
    AccordionComponent,
  },
  data() {
    return {
      openHours: [],
    };
  },
  props: {
    addressHeading: {
      type: String,
      default: '',
    },
    contentDescription: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    contactMap: {
      type: String,
      default: '',
    },
    schedule: {
      type: Object,
      default() {
        return {};
      },
    },
    branchPhoto: {
      type: Object,
      default() {
        return { url: '', alt: '' };
      },
    },
  },
  mounted() {
    this.openHours = Object.entries(this.schedule);
  },
  computed: {
    phoneTitle() {
      return this.phone.replace('tel:', '');
    },
    phoneLink() {
      return this.phone.startsWith('tel:') ? this.phone : `tel:${this.phone}`;
    },
    isPhotoSet() {
      return this.branchPhoto.url.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
