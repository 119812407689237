<template>
  <input
    :class="[
      $style.root,
      { [$style.error]: isError },
    ]"
    v-bind="$attrs"
    @input="hd"
    :type="type"
  />
</template>

<script>
export default {
  name: 'FormFieldInput',

  props: {
    type: {
      type: String,
      default: 'text',
    },
    isError: Boolean,
  },

  methods: {
    hd({ target }) {
      this.$emit('input', target.value);
    },
  },
};
</script>

<style lang="scss" module>
  @import './index';
</style>
