const expandMixin = {
  data() {
    return {
      isExpanded: false,
      defaultExpandTimeout: 150,
      observableSelector: '',
      observableScreenWidth: 500,
      observableExpandTimeout: 0,
    };
  },
  mounted() {
    if (this.observableSelector && window.screen.width < this.observableScreenWidth) {
      const targetElement = document.querySelector(this.observableSelector);
      const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              this.isExpanded = true;
            }, this.observableExpandTimeout);

            obs.unobserve(targetElement);
          }
        });
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      });

      observer.observe(targetElement);
    } else {
      setTimeout(() => {
        this.isExpanded = true;
      }, this.defaultExpandTimeout);
    }
  },
};

export default expandMixin;
