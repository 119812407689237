<template>
  <div class="circles-box">
    <div class="results-products__item-risk-indicator-v2"
         :class="{
          'results-products__item-risk-indicator-v2_stick': type === 'stick'
         }"
         v-for="n in risk"
         :key="n"></div>
    <slot />
  </div>
</template>

<script>
const DEFAULT_INDICATOR_TYPE = 'circle';
const STICK_INDICATOR_TYPE = 'stick';
export default {
  name: 'RiskIndicatorV2',
  data() {
    return {
      type: '',
    };
  },
  props: {
    risk: {
      type: Number,
      default: 0,
    },
    riskLimit: {
      type: Number,
      default: 5,
    },
    indicatorType: {
      type: String,
      default: DEFAULT_INDICATOR_TYPE,
    },
  },
  mounted() {
    console.log((this.indicatorTypes.includes(this.indicatorType)
      ? this.indicatorType
      : DEFAULT_INDICATOR_TYPE));
    this.type = (
      this.indicatorTypes.includes(this.indicatorType)
        ? this.indicatorType
        : DEFAULT_INDICATOR_TYPE
    );
  },
  computed: {
    indicatorTypes() {
      return [DEFAULT_INDICATOR_TYPE, STICK_INDICATOR_TYPE];
    },
  },
};
</script>

<style scoped lang="scss">
  .circles-box {
    display: flex;
    gap: .25rem;
    align-items: center;
    height: 100%;
  }
</style>
