<template>
  <VideoWrapper inline-template
    :url="url"
    :coverWebP="coverWebP"
    :cover="cover">
    <div class="video-wrapper">
      <components
          is="iframe"
          title="iframe"
          v-if="isPlay"
          :src="this.$attrs.url"
          frameborder="0"
          class="video-wrapper__iframe"
          allow="accelerometer; autoplay; clipboard-write;
          encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
      ></components>

      <transition name="video-wrapper-fade">
        <div
            v-if="!isPlay"
            class="video-wrapper__overlay"
            @click="handlePlayClick"
        >
          <picture class="video-wrapper__picture test">
            <source
                :srcset="this.$attrs.coverWebP"
                type="image/webp"
            >
            <img :src="this.$attrs.cover"
                alt=""
                class="video-wrapper__cover"
                loading="lazy"
                decoding="async"
                aria-hidden="true"
            />
          </picture>
          <button
              type="button"
              class="video-wrapper__overlay-play"
              aria-label="Play video"
          >
            <icons-component
                name="play"
                class="video-wrapper__overlay-play-icon"
            ></icons-component>
          </button>
        </div>
      </transition>
    </div>
  </VideoWrapper>
</template>

<script>
import VideoWrapper from './widgets/VideoWrapper/index.vue';

export default {
  name: 'YoutubeVideoComponent',
  components: {
    VideoWrapper,
  },
  data() {
    return {
      url: '',
    };
  },
  props: {
    cover: {
      type: String,
      default: '',
    },
    coverType: {
      type: String,
      default: '',
    },
    coverWebP: {
      type: String,
      default: '',
    },
    videoId: {
      type: String,
      default: '',
    },
    videoParams: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.url = `https://www.youtube.com/embed/${this.videoId}?${this.videoParams}`;
  },
};
</script>

<style scoped lang='scss'>
.video-wrapper {
  padding-top: initial;
  height: 100%;
}
</style>
