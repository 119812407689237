<template>
  <div>
    <h3
      v-if="title"
      :class="$style.heading"
    >{{ title }}</h3>
    <p
      v-if="isContentAvailable"
      :class="$style.content"
    ><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: 'DescriptionSection',

  props: {
    title: String,
  },

  computed: {
    isContentAvailable: ({ $slots }) => Object.keys($slots).length,
  },
};
</script>

<style lang="scss" module>
  .heading {
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    opacity: 0.98;
  }

  .content {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
</style>
