let announcementBar;

const hideAnnouncement = () => {
  announcementBar.style.height = `${announcementBar.offsetHeight}px`;

  setTimeout(() => {
    announcementBar.style.height = 0;
    sessionStorage.setItem('ziz-is-hide-announcement', '1');
  }, 0);
};

const catchButton = (e) => {
  const { target } = e;

  if (target.classList.contains('announcements-bar__block-btn')) {
    e.preventDefault();

    hideAnnouncement();
  }
};

const events = () => {
  document.addEventListener('click', catchButton);
};

const init = () => {
  const isHide = sessionStorage.getItem('ziz-is-hide-announcement');

  announcementBar = document.querySelector('.announcements-bar');

  if (announcementBar && !isHide) {
    announcementBar.classList.remove('announcements-bar--hide');

    events();
  }
};

export default {
  init,
};
