<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    :width="width"
    :height="height"
    v-once
  >
    <component :is="iconComponent" />
  </svg>
</template>

<script>
/* eslint-disable import/extensions */
import IconBellRing from './IconBellRing';
import IconChevronDown from './IconChevronDown';
import IconClick from './IconClick';
import IconClipboardHeart from './IconClipboardHeart';
import IconClock from './IconClock';
import IconClose from './IconClose';
import IconCurrency from './IconCurrency';
import IconDoorEnter from './IconDoorEnter';
import IconDriverLicense from './IconDriverLicense';
import IconEnvelope from './IconEnvelope';
import IconGoogle from './IconGoogle';
import IconHistory from './IconHistory';
import IconID from './IconID';
import IconPackage from './IconPackage';
import IconPassport from './IconPassport';
import IconPhone from './IconPhone';
import IconPlay from './IconPlay';
import IconRoundCheck from './IconRoundCheck';
import IconShield from './IconShield';
import IconSquareCheckFilled from './IconSquareCheckFilled';
import IconStar from './IconStar';
import IconStethoscope from './IconStethoscope';
import IconVNR from './IconVNR';

const icons = {
  'bell-ring': IconBellRing,
  'chevron-down': IconChevronDown,
  click: IconClick,
  'clipboard-heart': IconClipboardHeart,
  clock: IconClock,
  close: IconClose,
  currency: IconCurrency,
  'door-enter': IconDoorEnter,
  'driver-license': IconDriverLicense,
  envelope: IconEnvelope,
  google: IconGoogle,
  history: IconHistory,
  id: IconID,
  package: IconPackage,
  passport: IconPassport,
  phone: IconPhone,
  play: IconPlay,
  'round-check': IconRoundCheck,
  shield: IconShield,
  'square-check-filled': IconSquareCheckFilled,
  star: IconStar,
  stethoscope: IconStethoscope,
  vnr: IconVNR,
};

export default {
  name: 'IconsComponent',

  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    name: {
      type: String,
      required: true,
      validator: (value) => Object.prototype.hasOwnProperty.call(icons, value),
    },
  },

  computed: {
    iconComponent: ({ name }) => icons[name],
  },
};
</script>
