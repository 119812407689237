<template>
  <div class="d-inline-block">
    <a :href="href" class="btn btn-primary">Nog een keer proberen</a>
  </div>
</template>

<script>

import * as Sentry from '@sentry/vue';

export default {
  name: 'PaymentButton',
  data() {
    return {
      href: '/results',
    };
  },

  mounted() {
    this.$http.post('/receive/checkout', {
      data: this.$store.getters.USER,
      cart: this.$store.getters.CART,
      isCare: this.$store.getters.CARE,
    })
      .then((data) => {
        this.href = data.data.redirect_uri;
        this.$store.commit('SET_REDIRECT_URI', data.data.redirect_uri);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  },
};
</script>

<style scoped>

</style>
