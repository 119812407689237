<template>
  <div class="header__line" ref="line"></div>
</template>

<script>
export default {
  name: 'HeaderLine',
  props: {
    stage: {
      type: Number,
      default: 4,
    },
  },
  mounted() {
    const element = this.$parent.$refs[`stage-${this.stage}`];
    this.$refs.line.style.width = (this.stage === 4 ? '100%' : `${element.offsetLeft + element.offsetWidth}px`);
  },
};
</script>

<style scoped>

</style>
