<template>
  <div
    v-if="CART_DONATION"
    class="checkout-cart__product"
  >
    <div class="checkout-cart__product-info">
      <div class="checkout-cart__product-title">Donatie</div>
      <div class="checkout-cart__product-cost d-flex align-items-center">
        {{ formattedPrice }}
        <div
          v-if="isDynamic"
          role="button"
          class="checkout-cart__remove"
          title="Donaties verwijderen"
          @click="handleRemove"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isDynamic: Boolean,
  },

  computed: {
    ...mapGetters(['CART_DONATION']),

    formattedPrice() {
      return `€${this.CART_DONATION},-`;
    },
  },

  methods: {
    handleRemove() {
      this.$store.commit('CLEAR_DONATION');
    },
  },
};
</script>
