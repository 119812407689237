<template>
  <div :class="$style.root">
    <div :class="$style.head">
      <Icons name="round-check" :class="$style.icon" />
      <Heading type="h2">Betaling geslaagd!</Heading>
    </div>
    <p :class="$style.content">Je bent er bijna. <br />Voltooi de laatste stappen.</p>
  </div>
</template>

<script>
// import { AVAILABLE_STATUSES } from '@/pages/CheckoutStatus/constants';

// eslint-disable-next-line import/extensions
import Heading from '@/components/ui/Heading';
// eslint-disable-next-line import/extensions
import Icons from '@/components/Icons';

export default {
  name: 'StatusBox',

  components: {
    Heading,
    Icons,
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
