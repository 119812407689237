<script>
export default {
  name: 'ZizHeader',

  data: () => ({
    isShowMenu: false,
  }),

  methods: {
    handleMenuClick() {
      this.isShowMenu = document.documentElement.classList.toggle('show-mobile-menu');
    },
    handleDDClick(e) {
      if (window.innerWidth < 992) {
        e.preventDefault();
        e.target.parentNode.classList.toggle('ziz-header__nav-item_dd-open');
      }
    },
  },
};
</script>

<style lang="scss">
    @import './index';
</style>
