<template>
  <div>
    <ModalCheckoutComponent
        v-if="isOpen"
        @close="handleClose"
    >
      <template v-slot:title>
        <strong class="fz-1.5rem" v-html="title"></strong>
      </template>

      <slot />
    </ModalCheckoutComponent>
    <div class="wrapper" @click="handleOpen">
      <slot name="trigger"/>
    </div>
  </div>
</template>
<script>
import ModalCheckoutComponent from '../../ModalCheckout';

export default {
  name: 'ButtonModal',
  components: {
    ModalCheckoutComponent,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.isOpen = false;
    },
    handleOpen() {
      this.isOpen = true;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding: 0;
}

.fz-1\.5rem {
  font-size: 1.5rem;
}
</style>
