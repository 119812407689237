<template>
  <ul :class="[
      $style.list,
      { [$style.error]: $props.isError },
  ]">
    <li
        v-for="({ id, label, value, info, icon, disabled }) in list"
        :key="id"
        :class="$style.item"
    >
      <label
        :class="[
            $style.label,
            {[$style.active]: value === selected},
            {[$style.disabled]: disabled},
        ]"
      >
        <input
          :name="name"
          :value="value"
          :checked="selected"
          :disabled="disabled"
          type="radio"
          @click="handleChange"
        />
        <span :class="$style.radio" />
        <Icons
          v-if="icon"
          :name="icon"
          :class="$style.icon"
        />
        <span :class="$style.title">
          <span>{{ label }}</span>
          <span v-if="info" :class="$style.info">{{ info }}</span>
        </span>
      </label>
    </li>
  </ul>
</template>

<script>
// eslint-disable-next-line import/extensions
import Icons from '@/components/Icons';

export default {
  name: 'RadioRows',

  components: {
    Icons,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selected: {
      type: [Number, String],
      default: null,
    },
    isError: Boolean,
  },

  methods: {
    handleChange($event) {
      this.$emit('change', $event.target.value);
    },
  },
};
</script>

<style module lang="scss" scoped>
  @import './index';
</style>
