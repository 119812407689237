<template>
  <div :class="$style.root">
    <DescriptionSection :title="header.title">{{ header.content }}</DescriptionSection>

    <div :class="$style.content">
      <RadioRows
          v-if="!isSaved"
          :list="documentTypes"
          :selected="documentCurrent"
          @change="handleDocumentChange"
          name="documentType"
      />
      <DocumentInput />

      <div v-if="getCurrentInput && !isSaved">
        <button
          :class="[
            'btn btn-primary',
            $style.sendButton,
          ]"
          :disabled="isButtonDisabled"
          @click="toggleSaveDocument"
        >Opslaan</button>
      </div>

      <ModalContent />
    </div>
  </div>
</template>

<script>
import { documentTypeMixin } from '@/pages/CheckoutStatus/mixins/documentTypeMixin';

/* eslint-disable import/extensions */
import RadioRows from '@/components/RadioRows';

import DescriptionSection from '../DescriptionSection';
import DocumentInput from '../DocumentInput';
import ModalContent from '../ModalContent';

export default {
  name: 'DocumentRadio',

  components: {
    ModalContent,
    DescriptionSection,
    DocumentInput,
    RadioRows,
  },

  mixins: [documentTypeMixin],
};
</script>

<style lang="scss" module scoped>
  .root {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .sendButton {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
</style>
