<template>
    <swiper-container class="location-swiper"
      :pagination="false"
      :grab-cursor="true"
      :loop="true"
      :navigation="false"
      :space-between="24"
      :follow-finger="true"
      :short-swipes="false"
      :breakpoints="JSON.stringify({
        550: {
          slidesPerView: 1.3
        },
        470: {
          slidesPerView: 1.2
        },
        320: {
          slidesPerView: 1
        }
      })"
    >
        <swiper-slide
            v-for="(item, index) in branchesLocations"
            :key="index"
            :class="slideClass"
        >
            <ContactLocationComponent
                :address-heading="item.branchTitle"
                :content-description="item.description"
                :phone="item.phone"
                :contact-map="item.map"
                :schedule="item.schedule"
                :branch-photo="item.branchPhoto"
            />
        </swiper-slide>
    </swiper-container>
</template>

<script>
import { register } from 'swiper/element/bundle';
import ContactLocationComponent from './ContactLocationComponent.vue';

export default {
  name: 'ContactLocationSliderComponent',
  components: [ContactLocationComponent],
  props: {
    branchesLocations: {
      type: Array,
      default() {
        return [];
      },
    },
    slideClass: {
      type: String,
      default: '',
    },
  },
  mounted() {
    register();
  },
};
</script>

<style lang="scss">
  .location-swiper {
    width: 100%;
    height: 100%;
  }
</style>
