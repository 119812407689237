<template>
  <button
      v-if="buttonObject"
      :class="[
        'btn btn-primary',
        $style.sendButton,
      ]"
      :disabled="!isReadyToSend"
      @click="handleIntakeClick"
  >{{ buttonObject.title }}</button>
</template>

<script>
export default {
  name: 'ConfirmButton',

  inject: ['getIsReadyToSend', 'getButton'],

  computed: {
    isReadyToSend() {
      return this.getIsReadyToSend();
    },

    buttonObject() {
      return this.getButton();
    },
  },

  methods: {
    handleIntakeClick() {
      window.open(
        this.buttonObject.url,
        this.buttonObject.target ?? '_self',
        'noopener,noreferrer',
      );
    },
  },
};
</script>

<style lang="scss" module scoped>
.sendButton {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
</style>
