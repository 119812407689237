<template>
  <div class="donation-cart">
    <div class="donation-cart__title">Help jij stichting ZIZ groeien?</div>
    <div class="donation-cart__description">
      Doneer voor het openen van de volgende ZIZ locatie.
    </div>

    <div class="donation-cart__actions">
      <div class="donation-cart__actions-row">
        <button
          :key="`static-${staticDonate}`"
          v-for="staticDonate in staticDonations"
          type="button"
          :class="[
            'donation-cart__actions-btn',
            {
              'donation-cart__actions-btn--active': CART_DONATION === staticDonate && !isCustomValue
            },
          ]"
          @click="handleClick(staticDonate)"
        >{{ priceString(staticDonate) }}</button>
        <button
          type="button"
          :class="[
            'donation-cart__actions-btn',
            { 'donation-cart__actions-btn--active': isActiveCustom },
          ]"
          @click="handleCustom"
        >{{ customValueButtonText }}</button>
      </div>

      <div
        v-if="isCustomValue"
        class="donation-cart__actions-custom"
      >
        <input
          autofocus
          type="text"
          v-model="currentValueInt"
          v-mask="'€#########'"
        >
        <button
          type="button"
          class="donation-cart__actions-btn donation-cart__actions-btn--green"
          :disabled="!currentValue"
          @click="handleApplyCustom"
        >Toevoegen</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STATIC_DONATIONS } from '@/constants/donation-static';

export default {
  name: 'DonationCart',

  data: () => ({
    currentValue: 0,
    isCustomValue: false,
    isCustomSet: false,
  }),

  computed: {
    ...mapGetters(['CART_DONATION']),

    staticDonations: () => STATIC_DONATIONS,

    currentValueInt: {
      get: ({ currentValue }) => currentValue ?? 0,
      set(value) {
        this.currentValue = parseInt(value.match(/\d/g).join(''), 10) ?? 0;
      },
    },

    customValueButtonText() {
      return this.isActiveCustom
        ? this.priceString(this.currentValue)
        : 'Kies bedrag';
    },

    isActiveCustom() {
      return this.isCustomSet
          && !this.isCustomValue
          && this.staticDonations.indexOf(this.currentValue) === -1;
    },
  },

  methods: {
    handleClick(donationValue) {
      this.isCustomSet = false;

      if (donationValue === this.CART_DONATION) {
        this.$store.commit('CLEAR_DONATION');
        return;
      }

      this.isCustomValue = false;
      this.currentValue = donationValue;
      this.$store.commit('SET_DONATION', donationValue);
    },

    handleCustom() {
      if (this.isActiveCustom) {
        this.$store.commit('CLEAR_DONATION');
        this.isCustomSet = false;
        return;
      }

      this.isCustomValue = !this.isCustomValue;
    },

    handleApplyCustom() {
      this.$store.commit('SET_DONATION', this.currentValue);
      this.isCustomValue = false;
      this.isCustomSet = true;
    },

    priceString(value) {
      return `€${value},-`;
    },
  },

  watch: {
    CART_DONATION(newVal) {
      if (!newVal) {
        this.isCustomValue = false;
        this.isCustomSet = false;
        this.currentValue = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import './index';
</style>
