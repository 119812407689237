<template>
  <div v-if="predictionData" class="prediction-data">
    <h3 class="m-0 prediction-data__title">
      <span>We hebben bij jou</span>
      <span>
        <span class="main-red-color">
          {{ maxRisk }} risico's
        </span> op een soa gevonden*
      </span>
    </h3>

    <div class="container disease-container p-0">
      <div class="disease-row disease-row__hints">
        <div>Soa</div>
        <div>Risico</div>
      </div>
      <div class="disease-row"
           v-for="(product, index) in populatedProducts"
           :key="index">
        <div class="disease-row__inner-wrapper">
          <div class="disease-row__title-and-info">
            <span class="disease-row__title">
              {{ product.title }}
            </span>
            <div v-if="product.moreInfo.length > 0
              && (product.info?.cover || product.info?.accordion || product.info?.pageContent)"
                 class="disease-row__more-info">
              <ButtonModalPredictor :product-info="product.info"/>
            </div>
          </div>
          <RiskIndicatorV2 class="disease-row__show-desktop" :risk="product.risk"/>
          <RiskIndicatorV2
              class="disease-row__show-laptop"
              :risk="(product.risk > riskLimit ? riskLimit : product.risk)"
              :riskLimit="5"
          >
            <div v-if="product.risk > riskLimit"
                 class="results-products__item-risk-number">+{{ product.risk - riskLimit }}</div>
          </RiskIndicatorV2>
        </div>
      </div>
    </div>

    <div class="extra-info">
      <span class="extra-info_small">*Een risico kan voor meerdere soa's gelden.</span>
    </div>

    <div class="button-wrap mobile-only">
      <InfoButton :title="'Bekijk zorgpakket'" />
    </div>

    <div class="charts">
      <div class="charts_texts">
        <div class="charts__text-info">Jouw kans op een soa is daarmee</div>
        <div class="charts__heading-wrapper">
          <h4 v-if="isLowRisk"
              class="m-0 main-red-color">Verhoogd</h4>
          <h4 v-else class="m-0 main-red-color">Sterk verhoogd</h4>
        </div>
        <div v-if="isLowRisk"
             class="charts__text-info">Je hebt 3x meer kans op een soa</div>
        <div v-else class="charts__text-info">Je hebt 12x meer kans op een soa</div>
      </div>

      <ChartsLowRisk v-if="isLowRisk"/>
      <ChartsHighRisk v-else/>

      <ButtonModal
          class="button-wrap"
          :title="predictionData.prediction_title"
      >
        <template v-slot:trigger>
          <InfoButton />
        </template>

        <div>
          <div class="modal__prediction-text"
               v-html="predictionData.prediction_text"></div>
        </div>
      </ButtonModal>

      <div class="charts__bottom-text">
        We hebben jouw risico op een soa berekend met machine
        learning en de resultaten van meer dan 20.000 mensen.
      </div>
    </div>
  </div>
</template>
<script>
import ChartsHighRisk from './common/Charts/ChartsHighRisk';
import ChartsLowRisk from './common/Charts/ChartsLowRisk';
import InfoButton from './common/Button';
import ButtonModal from './common/ButtonModal';
import RiskIndicatorV2 from './RiskIndicatorV2';
import ButtonModalPredictor from './ButtonModalPredictor';
import productMixin from '@/mixins/productMixin';

export default {
  name: 'DiseasePredictorComponent',
  mixins: [productMixin],
  components: {
    RiskIndicatorV2,
    ChartsLowRisk,
    ChartsHighRisk,
    InfoButton,
    ButtonModal,
    ButtonModalPredictor,
  },
  data() {
    return {
      riskLimit: 5,
    };
  },
  props: {
    predictionData: {
      type: Object,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isLowRisk() {
      return this.predictionData.risk_class === 0;
    },
    populatedProducts() {
      return this.populateProducts(this.filteredProducts);
    },
    filteredProducts() {
      const productsTitles = [];
      return this.products.filter((product) => {
        if (productsTitles.includes(product.title)) {
          return false;
        }
        productsTitles.push(product.title);

        return true;
      });
    },
    maxRisk() {
      if (this.filteredProducts.length === 0) {
        return 0;
      }

      return Math.max(...this.filteredProducts.map((item) => item.risk));
    },
  },
};
</script>

<style scoped lang="scss">
.prediction-data {
  border-radius: .5rem;
  background: var(--Colors-Green-green-50, #E6F8F1);
  padding: 1.5rem;
  margin-bottom: 2.5rem;

  &__title {
    display: flex;
    flex-direction: column;
  }

  .button-wrap.mobile-only {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .main-red-color {
    color: var(--Foundation-Red-red-700, #B03A23);
  }

  .disease-container {
    margin: 1.5rem 0;

    .disease-row__inner-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;

      @media (max-width: 365px) {
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
      }

      .circles-box {
        flex: 1;
        justify-content: flex-end;
      }
    }

    .disease-row {
      margin-bottom: .75rem;

      &__title-and-info {
        display: flex;
        flex: 1;
        justify-content: space-between;

        @media (max-width: 1199px) {
          flex: 1.5;
        }

        @media (max-width: 991px) {
          flex: initial;
          gap: .75rem;
        }
      }

      &__show-laptop {
        @media (max-width: 515px) {
          display: flex;
        }

        @media (min-width: 516px) {
          display: none;
        }
      }

      &__show-desktop {
        @media (max-width: 515px) {
          display: none;
        }

        @media (min-width: 516px) {
          display: flex;
        }
      }

      &__hints {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: .5rem;
      }
    }

    .disease-row__first {
      margin-bottom: .75rem;
    }
  }

  .extra-info {
    font-weight: 500;
    color: #323846;
    line-height: 1.25rem;
    font-style: italic;
    margin-bottom: 1.5rem;
    &_small {
      font-size: .875rem;
    }
  }

  .charts {
    padding: 1rem 1rem 0 1rem;
    background-color: white;
    border-radius: .5rem;

    .button-wrap {
      padding: .75rem 0;
    }

    &__text-info {
      font-weight: 500;
      line-height: 1.5rem;
    }

    &_texts div {
      text-align: center;
      margin-bottom: .25rem;
    }

    &__bottom-text {
      padding: 0 1rem 1rem 1rem;
      color: var(--Colors-Black-B100, #70747E);
      text-align: center;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }
}
</style>
