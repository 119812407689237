<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <slot />
    </div>

    <button
        v-if="isShowButton"
        :class="$style.button"
        @click="handleClick"
        type="button"
    >{{ buttonText }}</button>
  </div>
</template>

<script>
import { isNil } from 'lodash/lang';

export default {
  name: 'AddressBox',

  props: {
    buttonText: String,
    buttonClick: Function,
  },

  computed: {
    isShowButton() {
      return !(isNil(this.buttonText) && isNil(this.click));
    },
  },

  methods: {
    handleClick() {
      this.$emit('button-click');
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
