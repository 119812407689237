const getGtmProduct = (product, i = 0) => ({
  item_id: product.id,
  item_name: product.title,
  currency: 'EUR',
  index: i + 1,
  item_category: product.type,
  item_list_name: document.title,
  item_variant: product.letter,
  // price: product.cost,
  price: `${product.careCost}.00`,
  quantity: 1,
});

export default getGtmProduct;
