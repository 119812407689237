<template>
  <div class="person__accent"
       :style="style"
       :class="rippleColor">
    <div class="lds-ripple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccentComponent',
  data() {
    return {
      rippleColor: 'success',
    };
  },
  props: {
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'success',
    },
    transferTo: {
      type: String,
      default: '',
    },
    transferDelay: {
      type: Number,
      default: 1000,
    },
  },
  mounted() {
    this.rippleColor = this.color;

    if (this.transferTo !== '') {
      const self = this;

      setTimeout(() => {
        self.rippleColor = self.transferTo;
      }, self.transferDelay);
    }
  },
  computed: {
    style() {
      return {
        top: `${this.top}px`,
        left: `${this.left}px`,
      };
    },
  },
};
</script>

<style scoped>

</style>
