<template>
  <div :class="$style.charts">
    <div :class="$style['charts__side-text']">
      <span>Kans op een soa</span>
    </div>
    <div :class="$style['charts__arrow-container']">
      <div :class="$style['charts__arrow-wrapper']">
        <div :class="[$style['charts__arrow'], isExpanded ? $style['animate'] : '']"></div>
      </div>
      <div></div>
    </div>
    <div id="charts" :class="$style['charts__results']">
      <div :class="$style['charts__result']">
        <div :class="$style['charts__result-number']">3%</div>
        <div
            :class="[$style['charts__result-rectangle'], isExpanded ? $style['expanded'] : '']"
        ></div>
      </div>
      <div :class="$style['charts__result']">
        <div :class="$style['charts__result-number']">10%</div>
        <div
            :class="[$style['charts__result-rectangle'], isExpanded ? $style['expanded'] : '']"
        ></div>
      </div>
    </div>
    <div :class="$style['charts__results-texts']">
        <div :class="$style['charts__result-description']">Basis</div>
        <div :class="$style['charts__result-description']">Verhoogd</div>
    </div>
  </div>
</template>

<script>
import expandMixin from '@/mixins/expandMixin';

export default {
  name: 'ChartsLowRisk',
  data() {
    return {
      observableSelector: '#charts',
    };
  },
  mixins: [expandMixin],
};
</script>

<style module lang="scss">
  @import './index';

  @media (max-width: 768px) {
    .charts__arrow-container .charts__arrow-wrapper {
      flex: 1.3;
    }
  }

  .charts__result {
    min-height: 11rem;
  }

  .charts__result:nth-child(1) .charts__result-rectangle {
    background: var(--Colors-Green-green-500, #02BD72);
  }

  .charts__result:nth-child(2) .charts__result-rectangle {
    background: var(--Foundation-Red-red-700, #B03A23);
  }

  .charts__result:nth-child(1) .expanded {
    height: 2.825rem;
  }

  .charts__result:nth-child(2) .expanded {
    height: 9.25rem
  }
</style>
