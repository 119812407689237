<template>
  <div :class="[
      $style.root,
      { [$style.error]: isError },
  ]">
    <label
      v-if="label"
      :class="$style.label"
    >{{ label }}</label>
    <component
      :is="fieldComponent"
      :isError="isError"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="`error-${index}`"
        :class="$style.errorText"
      >{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash/lang';
// eslint-disable-next-line import/extensions
import Input from './Input';

const AVAILABLE_ELEMENTS = {
  input: Input,
};

export default {
  name: 'FormField',

  inheritAttrs: false,

  props: {
    label: String,
    element: {
      type: String,
      default: Object.keys(AVAILABLE_ELEMENTS)[0],
      validator: (value) => Object.keys(AVAILABLE_ELEMENTS).includes(value),
    },
    errors: Array,
  },

  computed: {
    fieldComponent: ({ element }) => AVAILABLE_ELEMENTS[element],
    isError: ({ errors }) => !isEmpty(errors),
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
