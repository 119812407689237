document.addEventListener('DOMContentLoaded', () => {
  // Form Select option
  const fuiSelect = document.querySelector('.select-mod select');
  if (fuiSelect) {
    const fuiSelectText = document.querySelector('.js-select-hide-txt');
    fuiSelect.addEventListener('change', () => {
      fuiSelectText.classList.add('active');
    });
  }
  // Form file button option
  function buttonOption() {
    function buttonLoad() {
      const filesList = new DataTransfer();
      const fuiInputContainer = document.querySelector('.upload .fui-input-container');
      const addName = document.createElement('div');
      addName.className = 'js-file';
      addName.innerHTML = '<div class="js-file__name"></div>';
      fuiInputContainer.prepend(addName);
      const filesListWrap = document.querySelector('.js-file');
      const filesInput = document.querySelector('.upload input[type="file"]');
      const buildList = () => {
        const itemsMarkup = [...filesList.files].map((item, index) => `<div><span>${item.name}</span> <button type="button" class="remove-item"
        data-index="${index}"></button></div>`).join('');
        filesInput.files = filesList.files;
        filesListWrap.innerHTML = itemsMarkup;
        addName.classList.add('active');
        if (!itemsMarkup) {
          addName.classList.remove('active');
        }
        // console.log(itemsMarkup);
      };
      const handleUploadChange = (e) => {
        [...e.target.files].map((file) => filesList.items.add(file));
        buildList();
      };
      const handleRemove = (e) => {
        const { target } = e;
        if (target.classList.contains('remove-item')) {
          filesList.items.remove(target.dataset.index);
          buildList();
        }
      };
      filesInput.addEventListener('change', handleUploadChange);
      document.addEventListener('click', handleRemove);
    }

    function iOSversion() {
      let d;
      let v;
      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        d = {
          status: true,
          version: parseInt(v[1], 10),
          info: `${parseInt(v[1], 10)}.${parseInt(v[2], 10)}.${parseInt(v[3] || 0, 10)}`,
        };
      } else {
        d = { status: false, version: false, info: '' };
      }
      return d;
    }

    function buttonLoadOldIos() {
      const fuiInputContainer = document.querySelector('.upload .fui-input-container');
      const addName = document.createElement('div');
      addName.className = 'js-file';
      addName.innerHTML = '<div class="js-file__name"></div>';
      fuiInputContainer.prepend(addName);
      const addNameSpan = document.querySelector('.upload .js-file__name');
      const iFile = document.querySelector('.upload input[type="file"]');
      const closeBox = document.createElement('div');
      closeBox.className = 'js-file__clear';
      addName.append(closeBox);

      iFile.addEventListener('change', () => {
        // console.log(iFile.files.length);
        const srcFile = iFile.value;

        // console.log(srcFile);
        let split = srcFile.split('\\');
        if (split.length === 1 || split.length === 0) {
          split = srcFile.split('/');
        }
        // console.log(split);
        const nameFile = split[split.length - 1];

        function limitStr(str, n, symb) {
          if (!n && !symb) return str;
          if (str.length > n) {
            const symb2 = '...';
            return str.substr(0, n - symb2.length) + symb2;
          }
          return str.substr(0, n);
        }

        const nameFileLimit = limitStr(nameFile, 28);
        addNameSpan.innerHTML = nameFileLimit;
        const fileSum = iFile.files.length;
        if (fileSum >= 2) {
          const fileSumText = `${fileSum} bestanden geselecteerd`;
          addNameSpan.innerHTML = fileSumText;
        }
        addName.classList.add('active');
        if (!srcFile) {
          addName.classList.remove('active');
        }
      });

      closeBox.addEventListener('click', () => {
        addName.classList.remove('active');
        iFile.value = '';
        addNameSpan.innerHTML = '';
      });
    }

    const upload = document.querySelector('.upload');
    if (upload) {
      const iosVer = iOSversion();
      if (iosVer.version <= '14' && iosVer.version !== false) {
        buttonLoadOldIos();
        return false;
      }
      buttonLoad();
    }
    return true;
  }
  buttonOption();
});
