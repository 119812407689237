<script>
// eslint-disable-next-line import/extensions
import ListItem from './item';

export default {
  name: 'ZizAccordion',

  components: {
    ListItem,
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
