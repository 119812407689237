<template>
  <component
      :is="headingTag"
      :class="[
          'heading',
          `heading_${type}`,
      ]"
  ><slot/></component>
</template>

<script>
const AVAILABLE_HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export default {
  name: 'HeadingComponent',
  props: {
    type: {
      type: String,
      default: AVAILABLE_HEADINGS[0],
      validator: (value) => AVAILABLE_HEADINGS.includes(value),
    },
    tag: {
      type: String,
      validator: (value) => AVAILABLE_HEADINGS.includes(value),
    },
  },

  computed: {
    headingTag: ({ tag, type }) => tag ?? type,
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
