<template>
  <Modal
    v-if="isShow"
    @close="handleClose"
  >
    <Heading type="h3" :class="$style.title">{{ content.title }}</Heading>
    <div :class="$style.contentWrapper">
      <div :class="$style.content">
        <div :class="$style.type">
          <Icons :name="content.typeIcon" :class="$style.typeIcon" />
          <div :class="$style.typeTitle">{{ content.typeTitle }}</div>
        </div>
        <p
          v-if="content.text"
          v-html="content.text"
          :class="$style.text"
        />
      </div>
      <picture
        v-if="content.img"
        :class="$style.picture"
      >
        <img
          :src="content.img"
          :alt="content.title"
          :class="$style.image"
          width="240"
        />
      </picture>
    </div>
  </Modal>
</template>

<script>
/* eslint-disable import/extensions */
import Modal from '@/components/Modal';
import Heading from '@/components/ui/Heading';
import Icons from '@/components/Icons';

export default {
  name: 'ModalContent',

  components: {
    Modal,
    Heading,
    Icons,
  },

  inject: ['getIsShowPopup', 'toggleShowPopup', 'getPopupContent'],

  computed: {
    isShow() {
      return this.getIsShowPopup();
    },

    content() {
      return this.getPopupContent();
    },
  },

  methods: {
    handleClose() {
      this.toggleShowPopup();
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';
</style>
