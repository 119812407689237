<template>
  <form action="" novalidate>
    <field-group-component class="contact__field-group"
                           title="Reden contact"
                          :class="{'field-group--error': $v.form.reasonContact.$error}">
      <div :class="[ 'field-group__select', isSelectDefault ? 'default' : '' ]">
        <select v-model="$v.form.reasonContact.$model">
          <option value="Non selected" selected disabled>Selecteer een reden</option>
          <option :value="option.value"
                  v-for="(option, index) in reasonList"
                  :key="index">{{ option.title }}</option>
        </select>
      </div>
      <p v-if="form.reasonContact === 'Fout in het e-consult'" class="field-group__hint">
        Vergeet niet om de PDF van je e-consult toe te voegen als de vraag hier over gaat.
      </p>
      <template v-if="$v.form.reasonContact.$error">
        <span v-if="!$v.form.reasonContact.required"
              class="field-group__message field-group__message-error">
          Het veld is verplicht
        </span>
      </template>
    </field-group-component>

    <field-group-component class="contact__field-group"
                           title="Voornaam"
                           :class="{'field-group--error': $v.form.firstName.$error}">
      <input v-model.trim="$v.form.firstName.$model" type="text">
      <template v-if="$v.form.firstName.$error">
        <span v-if="!$v.form.firstName.required"
              class="field-group__message field-group__message-error">
          Het veld is verplicht
        </span>
      </template>
    </field-group-component>
    <field-group-component class="contact__field-group"
                           title="Achternaam"
                           :class="{'field-group--error': $v.form.lastName.$error}">
      <input v-model.trim="$v.form.lastName.$model" type="text">
      <template v-if="$v.form.lastName.$error">
        <span v-if="!$v.form.lastName.required"
              class="field-group__message field-group__message-error">
          Het veld is verplicht
        </span>
      </template>
    </field-group-component>

    <field-group-component class="contact__field-group"
                           title="Email"
                           :class="{'field-group--error': $v.form.email.$error}">
      <input v-model.trim="$v.form.email.$model" type="email">
      <template v-if="$v.form.email.$error">
        <span v-if="!$v.form.email.required"
              class="field-group__message field-group__message-error">
          Het veld is verplicht
        </span>
        <span v-if="!$v.form.email.email"
              class="field-group__message field-group__message-error">
          Ongeldig e-mailadres
        </span>
      </template>
    </field-group-component>

    <field-group-component class="contact__field-group"
                           title="Bijlage(n)">
      <div class="field-group__file-list">
        <p class="field-group__file-name" v-for="(file, index) in files" :key="index">
          <span>{{ file.name }}</span>
          <button class="btn"
                  title="Delete file"
                  type="button"
                  @click="deleteFile(index)"></button>
        </p>
      </div>
      <button class="btn btn-secondary"
              type="button"
              @click="triggerFileInput">
        Bestand uploaden
      </button>
      <input type="file"
             class="field-group__hidden"
             multiple
             tabindex="-1"
             ref="fileInput"
             @change="updateFileList">
    </field-group-component>

    <field-group-component class="contact__field-group"
                           title="Beschrijving">
      <div class="field-group__textarea"
           contenteditable="true"
           @input="inputMessage" />
      <input class="field-group__hidden"
             v-model="$v.form.message.$model"
             ref="message">
    </field-group-component>

    <button class="btn btn-primary"
            :class="{'disabled': $v.$invalid}"
            :disabled="$v.$invalid"
            @click.prevent="sendData">
      <span v-if="$v.$invalid">Opslaan</span>
      <span v-else>Versturen</span>
    </button>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, not, sameAs, email,
} from 'vuelidate/lib/validators';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import FieldGroupComponent from './FieldGroupComponent.vue';

export default {
  name: 'ContactFormComponent',
  components: {
    FieldGroupComponent,
  },
  data() {
    return {
      form: {
        reasonContact: 'Non selected',
        reasonContactDefault: 'Non selected',
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      },
      reasonList: [
        {
          value: 'Fout in het e-consult',
          title: 'Fout in het e-consult',
        },
      ],
      files: [],
    };
  },
  computed: {
    isSelectDefault() {
      return this.form.reasonContact === this.form.reasonContactDefault;
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        reasonContact: {
          required,
          not: not(sameAs('reasonContactDefault')),
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        message: {
          required,
        },
      },
    };
  },
  methods: {
    updateFileList(e) {
      e.target.files.forEach((fileItem) => {
        this.files.push(fileItem);
      });
    },
    deleteFile(index) {
      delete this.files.splice(index, 1);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    inputMessage(e) {
      this.$refs.message.value = e.target.innerText;
      this.form.message = this.$refs.message.value;
    },
    sendData() {
      this.$store.state.loading = true;
      // const filesArray = new FormData();
      // this.files.forEach((file, index) => {
      //   filesArray.append(`file[${index}]`, file);
      // });
      this.form.message = this.$refs.message.value;
      axios.post('/receive/contact', { data: this.form, files: this.files })
        .then((res) => console.log(res))
        .catch((err) => {
          Sentry.captureException(err);
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
