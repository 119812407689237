<template>
  <div :class="$style.root">
    <div class="row">
      <div class="col-4 col-lg-6">
        <FormField
            v-model.trim="$attrs.postcode.$model"
            :errors="postcodeErrors"
            v-mask="'#### XX'"
            placeholder="1111 AA"
            label="Postcode"
        />
      </div>
      <div class="col-4 col-lg-3">
        <FormField
            v-model="$attrs.number.$model"
            :errors="getRequireError('number')"
            v-mask="'#####'"
            placeholder="Nr."
            label="Huisnummer"
        />
      </div>
      <div class="col-4 col-lg-3">
        <FormField
            v-model="$attrs.additional.$model"
            placeholder="Toev."
            label="evt. Toevoeging"
        />
      </div>
    </div>

    <template v-if="isShowDeliveryAddress">
      <FormField
          v-model="$attrs.street.$model"
          :errors="getRequireError('street')"
          placeholder="Straat"
          label="Straat"
      />
      <FormField
          v-model="$attrs.city.$model"
          :errors="getRequireError('city')"
          placeholder="Stad"
          label="Stad"
      />
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import FormField from '@/components/ui/FormField';

const REQUIRE_ERROR_TEXT = 'Het veld is verplicht';

export default {
  name: 'AddressSearch',

  components: {
    FormField,
  },

  inheritAttrs: false,

  props: {
    isShowDeliveryAddress: Boolean,
  },

  computed: {
    postcodeErrors() {
      return Object.entries({
        'Ongeldige postcode': !this.$attrs.postcode.regular,
        [REQUIRE_ERROR_TEXT]: this.$attrs.postcode.$error && !this.$attrs.postcode.required,
      })
        .filter(([, isAvailable]) => isAvailable)
        .map(([title]) => title);
    },
  },

  methods: {
    getRequireError(attrSlug) {
      const isShowError = this.$attrs[attrSlug]?.$error && !this.$attrs[attrSlug]?.required;

      if (isShowError) {
        return [REQUIRE_ERROR_TEXT];
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss" module scoped>
  @import './index';;
</style>
