<script>
import youTubeParser from '@/helpers/youTubeParser';

// eslint-disable-next-line import/extensions
const Modal = () => import('@/components/Modal');

export default {
  name: 'HomeVideoBox',

  components: {
    Modal,
  },

  props: {
    videoLink: String,
  },

  data: () => ({
    isShowModal: false,
    places: null,
  }),

  computed: {
    videoId() {
      return youTubeParser(this.videoLink);
    },
    videoIframeSrc() {
      return `https://www.youtube.com/embed/${this.videoId}?rel=0&autoplay=1&showinfo=0`;
    },
  },

  methods: {
    handleModalVideo() {
      this.isShowModal = !this.isShowModal;
    },
  },
};
</script>
