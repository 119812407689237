// eslint-disable-next-line import/named
import { AVAILABLE_DOCUMENTS } from '@/pages/CheckoutStatus/constants';
// eslint-disable-next-line import/prefer-default-export
export const documentTypeMixin = {
  data: () => ({
    documentTypes: AVAILABLE_DOCUMENTS,
    isSaved: false,
    isShowPopup: false,
  }),

  provide() {
    return {
      getIsSaved: () => this.isSaved,
      getCurrentInput: () => this.getCurrentInput,
      getIsButtonDisabled: () => this.isButtonDisabled,
      getIsShowPopup: () => this.isShowPopup,
      getPopupContent: () => this.getPopupContent,

      toggleSaveDocument: this.toggleSaveDocument,
      toggleShowPopup: this.toggleShowPopup,
    };
  },

  inject: ['setIsReadyToSend', 'getDocumentNumber', 'setDocumentNumber', 'getDocumentCurrent', 'setDocumentCurrent', 'getZIZId'],

  methods: {
    handleDocumentChange($event) {
      this.setDocumentCurrent(parseInt($event, 10));
    },

    toggleSaveDocument() {
      this.isSaved = !this.isSaved;
    },

    toggleShowPopup(value) {
      this.isShowPopup = typeof value !== 'undefined' ? value : !this.isShowPopup;
    },
  },

  computed: {
    documentCurrent() {
      return this.getDocumentCurrent();
    },

    getCurrentType() {
      return AVAILABLE_DOCUMENTS.find((item) => item.value === this.documentCurrent);
    },

    getCurrentInput() {
      return this.getCurrentType?.input ?? null;
    },

    isButtonDisabled() {
      // TODO use proper validation
      return this.documentNumber.trim().length < (this.getCurrentType?.input?.mask?.length ?? 6);
    },

    header() {
      return {
        title: this.isSaved ? '1. Vul je documentnummer in' : '1. Documentnummer',
        content: this.isSaved ? null : 'Vul een documentnummer in van je paspoort, rijbewijs of ID-kaart.',
      };
    },

    getPopupContent() {
      const current = this.getCurrentType;

      return {
        title: current?.modal?.title ?? '',
        text: current?.modal?.text ?? '',
        img: current?.modal?.img ?? null,
        typeIcon: current?.icon ?? '',
        typeTitle: current?.label ?? '',
      };
    },

    documentNumber() {
      return this.getDocumentNumber();
    },

    currentDocumentLabel() {
      return AVAILABLE_DOCUMENTS.find((item) => item.value === this.documentCurrent)?.label ?? '';
    },

    zizid() {
      return this.getZIZId();
    },

    fetchData() {
      return {
        documentType: this.currentDocumentLabel,
        documentNumber: this.documentNumber,
        zizid: this.zizid,
      };
    },
  },

  watch: {
    isSaved(value) {
      this.setIsReadyToSend(value);

      if (value) {
        this
          .$http
          .post('/receive/document', this.fetchData)
          .catch((err) => console.error(err));
      }
    },
  },
};
