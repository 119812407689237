import PassportImage from '@/assets/images/documents/document-passport.png';
import DriverLicenseImage from '@/assets/images/documents/document-driver-license.png';
import IDImage from '@/assets/images/documents/document-id.png';
import VNRImage from '@/assets/images/documents/document-vnr.jpeg';

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_STATUSES = {
  paid: 'paid',
  cancel: 'cancel',
};

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_DOCUMENTS = [{
  id: 1,
  label: 'Paspoort',
  value: 1,
  icon: 'passport',
  input: {
    label: 'Paspoort documentnummer',
    placeholder: 'AA12345678',
    modalLinkText: 'Waar staat mijn documentnummer?',
  },
  modal: {
    title: 'Waar staat mijn\ndocumentnummer?',
    text: "Uw paspoortnummer bestaat uit negen tekens. Het bevindt zich rechts van je naam onder het kopje 'documentnummer'.",
    img: PassportImage,
  },
}, {
  id: 2,
  label: 'Rijbewijs',
  value: 2,
  icon: 'driver-license',
  input: {
    label: 'Rijbewijs nummer',
    placeholder: '1234567890',
    modalLinkText: 'Waar staat mijn rijbewijsnummer?',
    mask: '##########',
  },
  modal: {
    title: 'Waar staat mijn\nrijbewijsnummer?',
    text: 'Een rijbewijsnummer bestaat uit tien cijfers. Let op: een rijbewijsnummer is anders dan uw BSN-nummer.',
    img: DriverLicenseImage,
  },
}, {
  id: 3,
  label: 'ID-kaart',
  value: 3,
  icon: 'id',
  input: {
    label: 'ID-kaart documentnummer',
    placeholder: 'AA1234567',
    modalLinkText: 'Waar staat mijn documentnummer?',
  },
  modal: {
    title: 'Waar staat mijn\ndocumentnummer?',
    text: "Het documentnummer op een ID-kaart bestaat uit negen tekens. Het bevindt zich rechts van je naam onder het kopje 'documentnummer'.",
    img: IDImage,
  },
}, {
  id: 4,
  label: 'Verblijfsvergunning',
  value: 4,
  icon: 'vnr',
  input: {
    label: 'Verblijfsvergunning documentnummer',
    placeholder: '1234567890',
    modalLinkText: 'Waar staat mijn documentnummer?',
    mask: '##########',
  },
  modal: {
    title: 'Waar staat mijn\ndocumentnummer?',
    text: 'Onderaan de verblijfsvergunning staan de 10 cijfers van uw V-nummer. Dat nummer staat achter de letters VNR.',
    img: VNRImage,
  },
}];
