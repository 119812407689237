<template>
    <div class="results-products__more-info-body">
        <div v-if="productInfo.cover?.coverType &&
                  (
                      productInfo.cover.coverType === 'url'
                      || productInfo.cover.coverType === 'asset'
                  )"
             class="results-products__more-info-iframe"
        >
            <YoutubeVideoComponent v-if="productInfo.cover.coverType === 'url'"
                                   v-bind="productInfo.cover"/>
            <div v-if="productInfo.cover.coverType === 'asset'"
                 class="results-products__cover-img-wrapper"
            >
                <img :src="productInfo.cover.url"
                     alt=""
                     class="results-products__cover-img"
                     loading="lazy"
                     decoding="async"
                     aria-hidden="true"
                />
            </div>
        </div>
        <div class="results-products__more-info-content">
            <DefaultZizAccordion
                v-if="productInfo.accordion.length"
                :items="productInfo.accordion"
            />
            <div v-else>
                <div v-for="(content, index) in productInfo.pageContent"
                     :key="index"
                     v-html="filterMarkup(content)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import YoutubeVideoComponent from './YoutubeVideoComponent';
import DefaultZizAccordion from './DefaultZizAccordion';

export default {
  name: 'MoreInfoComponent',
  components: {
    DefaultZizAccordion,
    YoutubeVideoComponent,
  },
  props: {
    productInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    filterMarkup(html) {
      return html.replace(/(<iframe\b[^>]*\sstyle="[^"]*)\bwidth:\s*\d+px;?\s*/gi, '$1');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
