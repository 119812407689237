<template>
  <div>
    <div ref="tripetto" class="tripetto"></div>
  </div>
</template>

<script>
import { run } from '@tripetto/runner-autoscroll';
import { Export } from '@tripetto/runner';

import attachments from '../tripetto/filestore';

import definition from '../tripetto/definition.json';
import license from '../tripetto/license.json';
import styles from '../tripetto/styles.json';
import l10n from '../tripetto/l10n.json';

import tripettoTracking from '../helpers/tripettoTracking';

export default {
  name: 'TripettoFormComponent',
  data() {
    return {
      isLoaded: false,
      fields: [],
    };
  },
  props: {
    redirectTo: {
      type: String,
      default: '/results',
    },
    epilogueRedirectUrl: {
      type: String,
      default: 'https://ziz.nl/results',
    },
    postTo: {
      type: String,
      default: '/refresh-ziz-id',
    },
  },
  mounted() {
    definition.epilogue.redirectUrl = this.epilogueRedirectUrl;
    // console.log(this.epilogueRedirectUrl);
    let pushStateOnce = true;
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = (e) => {
      pushStateOnce = !pushStateOnce;
      window.history.go(1);
      if (pushStateOnce) {
        // if tripetto step === 0, back to previouse browser page
        // if tripetto step !== 0, back to previous tripetto step
        console.log(e);
        this.goBack();
      }
    };

    run({
      definition,
      removeBranding: true,
      license: license.key,
      element: this.$refs.tripetto,
      styles,
      l10n,
      attachments,
      onSubmit: this.onSubmit,
      onReady: this.onReady,
      onAction: (type, tripettoDefinition, tripettoBlock) => {
        this.onUpdate(type);
        tripettoTracking(type, tripettoDefinition, tripettoBlock, 'Consult form');
      },
    });

    console.log(run);
  },
  methods: {
    onReady() {
      const self = this;
      self.$store.commit('CLEAR_DATA');

      this.$http.post(self.postTo, {})
        .then((response) => console.log(response))
        .catch((error) => console.log(error));

      setTimeout(() => {
        this.isLoaded = true;
      }, 500);
    },
    onSubmit(instance) {
      const fields = Export.fields(instance); // TODO .fields = deprecated method
      const self = this;
      self.$store.commit('SET_ANAMNESIS', fields.fields);
      self.$store.commit('CLEAR_CART');
      self.$store.state.loading = true;
      setTimeout(() => {
        window.location.href = self.redirectTo;
      }, 1000);
    },
    onUpdate(e) {
      if (e === 'stage' && this.isLoaded) {
        this.setHeight();
      }
    },
    setHeight() {
      setTimeout(() => {
        const Tripetto = document.querySelector('iframe');
        const activeQuestion = Tripetto.contentWindow.document.querySelector('.sc-jSMfEi .jXDSvJ');
        if (activeQuestion !== null) {
          Tripetto.style.height = `${activeQuestion.offsetHeight}px`;
        }
      }, 250);
    },
    goBack() {},
  },
};

</script>

<style scoped>

</style>
