<template>
  <button v-if="CART.length !== 0"
          :disabled="disableButton"
          :class="classes"
          @click="redirect">{{ text }}</button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GoToCheckoutFormButtonComponent',
  props: {
    href: {
      type: String,
      default: '/checkout',
    },
    classes: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      default: 'Ga verder',
    },
    agreementLink: {
      type: Object,
      default: null,
    },
  },
  methods: {
    redirect() {
      window.location.href = this.href;
    },
  },
  computed: {
    ...mapGetters(['CART']),
    disableButton() {
      return (!this.$store.state.agreement && this.agreementLink !== null)
          || this.$store.state.care === null;
    },
  },
};
</script>

<style scoped>

</style>
