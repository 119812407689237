<template>
  <div class="header-icon" :class="{'show': isHeaderShow}" @click="toggleHeader"></div>
</template>

<script>
export default {
  name: 'HeaderToggleButtonComponent',
  data() {
    return {
      isHeaderShow: false,
    };
  },
  props: {
    toggleClass: {
      type: String,
      default: 'showed',
    },
  },
  methods: {
    toggleHeader() {
      this.isHeaderShow = !this.isHeaderShow;
      const { headerMenu } = this.$parent.$refs;
      if (this.isHeaderShow) {
        headerMenu.classList.add(this.toggleClass);
        document.body.classList.add('modal-open');
      } else {
        headerMenu.classList.remove(this.toggleClass);
        document.body.classList.remove('modal-open');
      }
    },
  },
};
</script>

<style scoped>

</style>
