<template>
  <div
    v-if="list"
    ref="root"
    class="ziz-reviews"
    :style="getRootStyles"
  >
    <div class="ziz-reviews__inner">
      <ul class="ziz-reviews__list">
        <li
            v-for="({ rating, text, author_name, time }) in list"
            :key="time"
            class="ziz-reviews__item"
        >
          <div class="ziz-reviews__box">
            <div class="ziz-reviews__box-content">
              <div class="ziz-reviews__box-rating">
                <GoogleRating :rating="rating" />
              </div>
              <div class="ziz-reviews__box-text">{{ text }}</div>
              <div class="ziz-reviews__box-author">{{ getAuthorName(author_name) }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <template v-if="totalPage > 1">
      <div class="ziz-reviews__arrows">
        <button
            class="ziz-reviews__arrows-btn"
            type="button"
            aria-label="Vorige dia"
            rel="prev"
            @click="movePage('prev')"
            :disabled="isPrevDisabled"
        >
          <Icons
              name="chevron-down"
              class="ziz-reviews__arrows-btn-icon"
          />
        </button>

        <button
            class="ziz-reviews__arrows-btn"
            type="button"
            aria-label="Volgende dia"
            rel="next"
            @click="movePage('next')"
            :disabled="isNextDisabled"
        >
          <Icons
            name="chevron-down"
            class="ziz-reviews__arrows-btn-icon"
          />
        </button>
      </div>

      <div class="ziz-reviews__dots">
        <button
          v-for="dot in totalPage"
          :key="dot"
          :class="[
            'ziz-reviews__dots-item',
            { 'ziz-reviews__dots-item_active': page === dot - 1 },
          ]"
          @click="moveToPage(dot - 1)"
          type="button"
          aria-hidden="true"
          tabindex="-1"
        >{{ dot }}</button>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import GoogleRating from '@/components/ui/GoogleRating';
import Icons from '@/components/Icons';

const PAGE_SIZE = 3;

export default {
  name: 'ZizReviews',

  components: {
    GoogleRating,
    Icons,
  },

  props: {
    list: Array,
    perPage: {
      type: Number,
      default: PAGE_SIZE,
    },
  },

  data: () => ({
    page: 0,
    isMounted: false,
  }),

  computed: {
    totalPage() {
      return Math.ceil(this.list.length / this.perPage);
    },
    gapSize() {
      if (this.isMounted) {
        return window.getComputedStyle(this.$refs.root).getPropertyValue('--item-gap') || 24;
      }

      return 0;
    },
    getOffsetStyles() {
      return {
        transform: `translateX(calc(-${this.page * 100}% - ${this.page * this.gapSize}px)`,
      };
    },
    getRootStyles() {
      return {
        '--per-page': this.perPage,
      };
    },
    isPrevDisabled() {
      return this.page === 0;
    },
    isNextDisabled() {
      return this.page === this.totalPage - 1;
    },
  },

  methods: {
    movePage(dir = 'next') {
      const nextPage = dir === 'next'
        ? this.page + 1
        : this.page - 1;

      if (nextPage >= this.totalPage) {
        this.page = 0;
        return;
      }

      if (nextPage < 0) {
        this.page = this.totalPage - 1;
        return;
      }

      this.page = nextPage;
    },
    moveToPage(nextPage) {
      this.page = nextPage;
    },
    getAuthorName(fullName) {
      const fullNameArr = fullName.split(' ');

      return fullNameArr.length > 2
        ? fullNameArr.slice(0, -1).join(' ')
        : fullNameArr[0];
    },
  },

  watch: {
    page: {
      handler() {
        this.$refs.root.style.setProperty('--current-page', this.page);
      },
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
