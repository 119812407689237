<template>
  <div>
    <slot v-if="!readyToSwitch" name="switch-from"/>
    <slot v-else name="switch-to"/>
  </div>
</template>

<script>
export default {
  name: 'ContentSwitcherComponent',
  data() {
    return {
      windowSize: 0,
    };
  },
  props: {
    breakpointSwitch: {
      type: Number,
      default: 1200,
    },
  },
  mounted() {
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  computed: {
    readyToSwitch() {
      return this.breakpointSwitch > this.windowSize;
    },
  },
  methods: {
    updateWindowSize() {
      const { innerWidth } = window;
      const screenWidth = window.screen.width;
      let windowSize = (innerWidth > 0) ? innerWidth : screenWidth;
      if (screenWidth > 0 && screenWidth < innerWidth) {
        windowSize = screenWidth;
      }

      this.windowSize = windowSize;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
