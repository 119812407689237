const tripettoTracking = (trackedAction, trackedDefinition, trackedBlock, formName = 'Form') => {
  if (window.dataLayer === undefined) {
    return;
  }

  const GTM = window.dataLayer;
  switch (trackedAction) {
    case 'start':
      GTM.push({
        event: 'tripetto_start',
        description: `${formName} is started.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
      });
      break;
    case 'pause':
      GTM.push({
        event: 'tripetto_pause',
        description: `${formName} is paused.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
      });
      break;
    case 'complete':
      GTM.push({
        event: 'tripetto_complete',
        description: `${formName} is completed.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
      });
      break;
    case 'stage':
      GTM.push({
        event: 'tripetto_stage',
        description: `${formName} block becomes available.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
        block: trackedBlock.name,
        key: trackedBlock.id,
      });
      break;
    case 'unstage':
      GTM.push({
        event: 'tripetto_unstage',
        description: `${formName} block becomes unavailable.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
        block: trackedBlock.name,
        key: trackedBlock.id,
      });
      break;
    case 'focus':
      GTM.push({
        event: 'tripetto_focus',
        description: `${formName} input element gained focus.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
        block: trackedBlock.name,
        key: trackedBlock.id,
      });
      break;
    case 'blur':
      GTM.push({
        event: 'tripetto_blur',
        description: `${formName} input element lost focus.`,
        form: trackedDefinition.name,
        fingerprint: trackedDefinition.fingerprint,
        block: trackedBlock.name,
        key: trackedBlock.id,
      });
      break;
    default:
      break;
  }
};

export default tripettoTracking;
