<template>
  <div class="loading__wrapper">
    <AccentComponent/>
  </div>
</template>

<script>
import AccentComponent from './AccentComponent.vue';

export default {
  name: 'LoadingComponent',
  components: {
    AccentComponent,
  },
  mounted() {
    document.body.classList.add('modal-open');
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open');
  },
};
</script>

<style scoped>

</style>
