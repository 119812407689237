<template>
  <component
      :is="tag"
      :class="$style.root"
  ><slot/></component>
</template>

<script>
const AVAILABLE_TAGS = ['a', 'button'];

export default {
  name: 'LinkComponent',

  props: {
    tag: {
      type: String,
      default: AVAILABLE_TAGS[0],
      validator: (value) => AVAILABLE_TAGS.includes(value),
    },
  },
};
</script>

<style lang="scss" module scoped>
  .root {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-decoration-line: underline;
    color: $blue-500;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    border: 0;
    background: transparent;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
      line-height: 20px;
    }
  }
</style>
