<template>
  <div class="donation-value">
    <div class="donation-value__list">
      <FormWrapper
        :key="`static-form-${staticDonate}`"
        v-for="staticDonate in staticDonations"
        :form="form"
        :csrfToken="csrfToken"
        :amount="staticDonate"
      >
        <button
          class="btn btn-primary page-donations__progress-btn"
          :disabled="!csrfToken"
        >€{{ staticDonate }},-</button>
      </FormWrapper>

      <button
        class="btn btn-primary page-donations__progress-btn page-donations__progress-btn--wide"
        :disabled="!csrfToken"
        @click="handleCustomValue"
      >Ander bedrag</button>
    </div>

    <FormWrapper
      v-if="isShowCustomValue"
      :form="form"
      :csrfToken="csrfToken"
      :amount="currentValueInt"
      class="donation-value__form"
    >
      <input
        autofocus
        type="text"
        class="donation-value__input"
        v-model="currentValueInt"
        v-mask="'€#########'"
      >
      <button class="btn btn-primary">Doneer</button>
    </FormWrapper>
  </div>
</template>

<script>
import { STATIC_DONATIONS } from '@/constants/donation-static';
import FormWrapper from './formWrapper.vue';

export default {
  name: 'DonationValue',

  props: {
    form: Object,
  },

  components: {
    FormWrapper,
  },

  data: () => ({
    currentValue: 15,
    isShowCustomValue: false,
    csrfToken: null,
  }),

  computed: {
    staticDonations: () => STATIC_DONATIONS,

    currentValueInt: {
      get: ({ currentValue }) => currentValue ?? 0,
      set(value) {
        this.currentValue = parseInt(value.match(/\d/g).join(''), 10);
      },
    },
  },

  methods: {
    handleCustomValue() {
      this.isShowCustomValue = !this.isShowCustomValue;
    },
  },

  beforeCreate() {
    fetch('/actions/blitz/csrf/token')
      .then((res) => res.text())
      .then((token) => {
        this.csrfToken = token;
      });
  },
};
</script>

<style lang="scss" scoped>
  @import './index';
</style>
