import Vue from 'vue';
// eslint-disable-next-line import/no-unresolved
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { isDisableHomeService } from './helpers/disableHouseCall';
import getGtmProduct from './helpers/gtmProduct';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    anamnesis: [],
    cart: [],
    care: true,
    agreement: false,
    loading: false,
    redirect_uri: '',
    user: {
      firstName: '',
      lastName: '',
      birthdayDate: '',
      email: '',
      phoneNumber: '',
      postcode: '',
      number: '',
      additional: '',
      street: '',
      city: '',
      isHomeTest: false,
      ZIZGaaga: false,
      gender: undefined,
      trans: false,
    },
    donation: null,
    allProducts: {}, // Добавляем объект для хранения всех продуктов
  },
  getters: {
    ANAMNESIS: (state) => state.anamnesis,
    IS_ANAMNESIS_VALID: (state) => Array.isArray(state.anamnesis) && state.anamnesis.length !== 0,
    USER: (state) => state.user,
    REDIRECT: (state) => state.redirect_uri,
    CARE: (state) => state.care,
    CART: (state) => state.cart.filter((product) => product && product.available)
      .sort((a, b) => {
        if (a.letter === null) return 1;
        if (b.letter === null) return -1;
        const letterA = a.letter.toLowerCase();
        const letterB = b.letter.toLowerCase();
        if (letterA === 't') return -1; // T always on top
        if (letterB === 't') return 1;
        return letterA.localeCompare(letterB);
      }),
    // eslint-disable-next-line default-param-last
    CART_SUM: (state, getters) => getters.CART.reduce((sum = 0, elem) => {
      const cost = (state.care ? elem.careCost : elem.cost);

      return sum + cost;
    }, 0) + (state.donation ?? 0),
    CART_CHECK_PRODUCT: (state) => (id) => state.cart.find(
      (product) => product && product.id === id,
    ),
    CART_CHECK_PRODUCT_BY_LETTER: (state) => (letter) => state.cart.find(
      (product) => product && product.letter === letter,
    ),
    CART_DONATION: (state) => state.donation,
    IS_BLOOD_TEST_EXISTS: (state) => state.cart.find((product) => product && product.type === 'bloed'),
    // TODO get real product
    IS_HOME_VISIT: (state) => state.cart.some((product) => product?.id === 0),
    IS_DISABLE_HOME_VISIT: (state) => isDisableHomeService(state.cart.map((item) => item?.letter))
        || state.cart.filter(Boolean).length === 0,
    ALL_PRODUCTS: (state) => state.allProducts, // Добавляем геттер для всех продуктов
  },
  mutations: {
    SET_USER: (state, payload) => {
      Vue.set(state, 'user', payload);
    },
    SET_REDIRECT_URI: (state, payload) => {
      state.redirect_uri = payload;
    },
    SET_ANAMNESIS: (state, payload) => {
      state.anamnesis = payload;
    },
    SET_CARE: (state, care) => {
      state.care = care;
    },
    SET_AGREEMENT: (state, agreement) => {
      state.agreement = agreement;
    },
    SET_ALL_PRODUCTS: (state, products) => {
      state.allProducts = products;
    },
    ADD_TO_CART: (state, payload) => {
      Vue.set(state.cart, payload.id, payload);
      // Mutually exclusive diagnoses.
      // Each diagnosis has a letter.
      // The key is a diagnosis that combines the diagnoses from the array.
      const listProducts = {
        T: ['A', 'B', 'C', 'D'],
      };
      // PrEP products are not allowed to be combined with each-other
      // If an additional PrEP product is selected,
      // the other PrEP product should be removed from basket.
      if (['W', 'X', 'V'].includes(payload.letter)) {
        state.cart.filter((val) => val !== null)
          .filter((item) => item.letter !== payload.letter)
          .filter((item) => ['W', 'X', 'V'].includes(item.letter))
          .forEach((p) => Vue.set(state.cart, p.id, null));
      }
      Object.entries(listProducts).forEach((product) => {
        if (payload.letter === product[0]) {
          state.cart.filter((item) => item).forEach((_product) => {
            if (product[1].includes(_product.letter)) {
              Vue.set(state.cart, _product.id, null);
            }
          });
        }
        if (product[1].includes(payload.letter)) {
          state.cart.filter((item) => item).forEach((_product) => {
            if (_product.letter === product[0]) {
              Vue.set(state.cart, _product.id, null);
            }
          });
        }
      });

      // Add mandatory products O and U if V is added
      if (payload.letter === 'V') {
        const mandatoryProducts = ['O', 'U'];
        mandatoryProducts.forEach((letter) => {
          const product = state.allProducts.find((item) => item.letter === letter);
          if (product && !state.cart.find((item) => item && item.letter === letter)) {
            Vue.set(state.cart, product.id, product);
          }
        });
      }

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            getGtmProduct(payload, 0)],
        },
      });
    },

    REMOVE_FROM_CART: (state, payload) => {
    // If click the HIV-test (L) out of the basket, the PrEP (W, X, V) should leave the basket too.
      if (payload.letter === 'L') {
        state.cart.filter((val) => val !== null)
          .filter((item) => ['W', 'X', 'V'].includes(item.letter))
          .forEach((p) => Vue.set(state.cart, p.id, null));
      }

      Vue.set(state.cart, payload.id, null);
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            getGtmProduct(payload, 0)],
        },
      });
    },

    SET_DONATION: (state, payload) => {
      state.donation = payload;
    },
    CLEAR_DONATION: (state) => {
      state.donation = null;
    },

    CLEAR_DATA: (state) => {
      state.cart = [];
      state.anamnesis = [];
      state.care = true;
      state.agreement = false;
      state.loading = false;
      state.redirect_uri = '';
      state.user = {};
      state.donation = null;
    },
    CLEAR_CART: (state) => {
      state.cart = [];
    },
  },
  plugins: [createPersistedState()],
});
