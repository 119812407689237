<template>
  <div class="col-12 col-md-6">
    <div class="results-products__item">
      <div class="row p-4">
        <template v-if="product.risk && product.risk > 0">
          <div class="col-12 d-flex align-items-center mb-3"
               v-if="!['X', 'W', 'V', 'U'].includes(product.letter)">
              <span class="results-products__item-risk">
                  Gevonden risico's
              </span>
            <span v-for="index in product.risk"
                  :key="index"
                  class="results-products__item-risk-indicator"></span>
          </div>
        </template>
        <div class="col-12">
          <div class="results-products__item-title">{{ product.title }}</div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
          <div class="results-products__item-type">{{ product.type }}</div>
          <div class="results-products__item-price">{{ cost }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="results-products__item-info"
               :class="dangerClass">
            <show-video-button-component
                v-if="product.embed"
                title="Uitleg">
              <div v-html="product.embed"></div>
            </show-video-button-component>
            <div v-else></div>
            <div v-if="productDisabledMessage(product, $store).length"
                 class="text-muted"
                 v-html="productDisabledMessage(product, $store)"
            />
            <a v-else-if="!$store.getters.CART_CHECK_PRODUCT(product.id)"
               href="#"
               class="results-products__item-add_to_cart"
               @click.prevent="addToCart">
              Toevoegen
            </a>
            <a v-else
               href="#"
               class="results-products__item-add_to_cart added_to_cart"
               :class="{ 'disabled': productIsMandatory(product, $store) }"
               @click.prevent="removeFromCart">
              {{ addToButtonText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dangerProducts from '../helpers/dangerProducts';

export default {
  name: 'ResultProductComponent',
  props: {
    product: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['CARE', 'CART_CHECK_PRODUCT_BY_LETTER']),
    cost() {
      const cost = this.CARE ? this.product.careCost : this.product.cost;
      return `€${cost},-`;
    },
    addToButtonText() {
      return this.type === 'required' ? 'Aanbevolen' : 'Verwijderen';
    },
    dangerClass() {
      const l = this.product.letter;
      return (dangerProducts.some((letter) => letter === l) && this.product.risk > 0) ? 'item--danger' : null;
    },
  },
  mounted() {
    if (this.type === 'required') {
      this.addToCart();
    }
  },
  methods: {
    productDisabledMessage(product, store) {
      if (['W', 'X', 'V'].includes(product.letter)) {
        if (!store.getters.CART_CHECK_PRODUCT_BY_LETTER('L')) {
          return 'HIV-test verplicht';
        }
      }
      if (!product.available) {
        return 'Binnenkort beschikbaar';
      }
      return '';
    },
    productIsMandatory(product, store) {
      if (['O', 'U'].includes(product.letter)) {
        if (store.getters.CART_CHECK_PRODUCT_BY_LETTER('V')) {
          return true;
        }
      }
      return false;
    },
    addToCart() {
      this.$store.commit('ADD_TO_CART', this.product);
    },
    removeFromCart() {
      this.$store.commit('REMOVE_FROM_CART', this.product);
    },
  },
};
</script>

<style scoped>
.disabled {
  color: #808080FF;
  pointer-events: none;
}
</style>
