// TODO Remove this, after getting information from backend
/* eslint-disable import/prefer-default-export */
export const AVAILABLE_HOME_SERVICE_LETTERS = [
  // Chlamydia & Gonorroe
  'A',
  'B',
  'C',
  'D',
  'T',
  // Trichomonas
  'E',
  'F',
  // Mycoplasma genitalium
  'G',
  'H',
  // Syfilis
  'I',
  'K',
  // HIV
  'L',
  // Hepatitis B
  'M',
  // Hepatitis C
  'O',
  'P',
  // Kreatinine
  // 'U',
];
// E-consult dermatoloog:
// Possible to order as test-at-home with another test-at-home only, but not one itself
export const E_CONSULT_LETTER = 'Q';

export const isDisableHomeService = (value) => {
  const availableType = value instanceof String || value instanceof Array;

  if (!availableType) {
    console.error('Wrong type of argument');
    return false;
  }

  if (Array.isArray(value)) {
    if (value.filter(Boolean).filter((v) => v !== E_CONSULT_LETTER).length) {
      return !value
        .filter(Boolean)
        .filter((v) => v !== E_CONSULT_LETTER)
        .every((valueItem) => AVAILABLE_HOME_SERVICE_LETTERS.includes(valueItem));
    }

    return true;
  }

  return !AVAILABLE_HOME_SERVICE_LETTERS.includes(value);
};
