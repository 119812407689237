import Vue from 'vue';

const productMixin = {
  data() {
    return {
      productInfoStorage: [],
    };
  },
  methods: {
    getInfoByTitle(product, title) {
      if (!product.moreInfo.length) {
        return false;
      }

      const searchTitle = title.toLowerCase();
      if (this.productInfoStorage[searchTitle]) {
        return this.productInfoStorage[searchTitle];
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const info of product.moreInfo) {
        if (info.descriptionTitle.toLowerCase() === searchTitle) {
          Vue.set(this.productInfoStorage, searchTitle, info);

          return info;
        }
      }

      return product.moreInfo[0];
    },
    getProductTitle(productTitle) {
      if (this.isChlamydiaAndGonorroe(productTitle)) {
        return productTitle.split('&')[0].trim();
      }

      return productTitle.trim();
    },
    isChlamydiaAndGonorroe(productTitle) {
      return productTitle.toLowerCase() === 'chlamydia & gonorroe';
    },
    populateProducts(products) {
      const populatedProducts = [];
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const product of products) {
        if (this.isChlamydiaAndGonorroe(product.title)) {
          const productCopy = JSON.parse(JSON.stringify(product));
          productCopy.title = this.getProductTitle(productCopy.title);
          productCopy.info = this.getInfoByTitle(productCopy, productCopy.title);

          const gonorroeProduct = JSON.parse(JSON.stringify(product));
          gonorroeProduct.id += '_copy';
          gonorroeProduct.title = gonorroeProduct.title.split('&')[1].trim();
          gonorroeProduct.info = this.getInfoByTitle(gonorroeProduct, gonorroeProduct.title);

          populatedProducts.push(productCopy);
          populatedProducts.push(gonorroeProduct);
        } else {
          product.info = this.getInfoByTitle(product, product.title);
          populatedProducts.push(product);
        }
      }

      return populatedProducts;
    },
  },
};

export default productMixin;
