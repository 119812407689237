import Vue from 'vue';
import './components';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import store from './store';
import 'bootstrap';
import './scss/main.scss';
import './formie/formie';
import Announcement from './components/widgets/Announcement';

Vue.config.productionTip = false;
// Vue.config.devtools = true;

Sentry.init({
  Vue,
  dsn: 'https://687567c6261b4f498adbbea99787d8d4@sentry.yournext.agency/58',
});

// Init announcement
Announcement.init();

Vue.use(Vuelidate);
Vue.use(VueTheMask);

Vue.prototype.$http = axios.create({
  headers: {
    'X-CSRF-Token': window.csrfToken,
  },
  timeout: 60000,
});

Vue.prototype.$http.interceptors.request.use(async (config) => {
  const selfConfig = { ...config };

  try {
    const response = await fetch('/actions/blitz/csrf/token');

    selfConfig.headers['X-CSRF-Token'] = await response.text();
  } catch (_) {
    // empty
  }

  return selfConfig;
});

// eslint-disable-next-line no-new
new Vue({
  store,
  el: '#app',
  delimiters: ['${', '}'],
});
