<template>
  <Reviews v-if="reviews" :list="reviews" />
</template>

<script>

/* eslint-disable import/extensions */
import Reviews from '@/components/widgets/Reviews';

export default {
  name: 'HomeReviews',

  components: {
    Reviews,
  },

  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
};
</script>
