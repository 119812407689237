<script>
export default {
  name: 'VideoWrapper',

  data: () => ({
    isPlay: false,
  }),

  methods: {
    handlePlayClick() {
      this.isPlay = true;
    },
  },
};
</script>

<style lang="scss">
  @import './index';
</style>
