import axios from 'axios';

const fileStoreUrl = '/receive/image';

const attachments = {};

attachments.get = (file) => new Promise((resolve, reject) => {
  axios.get(`${fileStoreUrl}/${file}`, { responseType: 'blob' })
    .then((res) => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject();
      }
    })
    .catch(() => reject());
});

attachments.put = (file, onProgress) => new Promise(((resolve, reject) => {
  const formData = new FormData();
  formData.append('UploadFile[file]', file);
  axios.post(fileStoreUrl, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    },
  })
    .then((res) => {
      if (res.status === 200 && res.data && res.data.name) {
        return resolve(res.data.name);
      }

      return reject(res.status === 413 ? 'File is too large.' : undefined);
    })
    .catch(() => reject());
}));

attachments.delete = (file) => new Promise(((resolve, reject) => {
  axios.delete(`${fileStoreUrl}/unconfirmed`, { data: { file } })
    .then((res) => {
      if (res.status === 200) {
        return resolve();
      }

      return reject();
    })
    .catch(() => reject());
}));

export default attachments;
