<template>
  <div class="popup-content popup-content__wrapper d-flex faq-detail__article">
    <div class="container popup-content__container m-auto">
      <div class="row">
        <div class="col-10 offset-1 position-static">
          <div class="popup-content__backdrop" @click="$emit('closePopup')"></div>
          <div class="position-relative">
            <div class="popup-content__close" @click="$emit('closePopup')"></div>
            <div class="popup-content__in embed-responsive">
              <slot/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupComponent',
  methods: {
    escapeHandler(e) {
      if (e.key === 'Escape') {
        this.$emit('closePopup');
      }
    },
  },
  mounted() {
    document.body.classList.add('modal-open');
    document.addEventListener('keydown', this.escapeHandler);
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open');
    document.removeEventListener('keydown', this.escapeHandler);
  },
};
</script>

<style scoped lang="scss">

</style>
