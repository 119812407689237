<template>
  <form method="post">
    <input
      type="hidden"
      :name="form.csrfName"
      :value="csrfToken"
    >
    <input
      type="hidden"
      name="action"
      :value="form.action"
    >
    <input
      type="hidden"
      name="redirect"
      :value="form.redirect"
    >
    <input
      type="hidden"
      name="form"
      :value="form.form"
    >
    <input
      type="hidden"
      name="email"
      :value="form.email"
    >
    <input
      type="hidden"
      name="amount"
      :value="amount"
    >
      <slot />
  </form>
</template>

<script>
export default {
  name: 'DonationValueFormWrapper',

  props: {
    form: Object,
    csrfToken: String,
    amount: Number,
  },
};
</script>
